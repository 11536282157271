import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {GiftService} from '../gift-information/gift.service';
import {catchError, map, tap} from 'rxjs/operators';
import {Gift} from '../gift-information/gift';
import {NavigationService} from '../shared/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class ReceiptCustomerGuard implements CanActivate {

  constructor(private readonly giftService: GiftService,
              private readonly navigationService: NavigationService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const giftId = next.queryParams['ID'];
    if (!giftId) {
      return this.navigateToHome();
    }
    return this.giftService.retrieveGiftWithId(+giftId).pipe(
        tap(gift => {
          if (this.needsCustomerQueryParam(gift, next)) {
            this.navigateToSelfWithCustomer(gift, next);
          }
        }),
        map(() => true),
        catchError(() => this.navigateToHome())
    );
  }

  needsCustomerQueryParam(gift: Gift, next: ActivatedRouteSnapshot): boolean {
    const customer = next.queryParams['customer'];
    return gift.customer && (!customer || customer !== gift.customer);
  }

  navigateToSelfWithCustomer(gift: Gift, next: ActivatedRouteSnapshot) {
    this.navigationService.navigateToSelfWithCustomer(gift, next);
  }

  navigateToHome() {
    this.navigationService.navigateToHome();
    return of(false);
  }

}
