<div *ngIf="isDonationFromBusiness; else personalDonation">
    <h2 class="form-label-underline">Business Contact Information</h2>
</div>
<ng-template #personalDonation>
    <h2 class="form-label-underline">Personal Information</h2>
</ng-template>
<div [formGroup]="form">
    <div class="row mb-3">
        <label for="title" class="col-sm-3 col-form-label"><strong>Title:</strong></label>
        <div class="col-sm-3">
            <select id="title" class="form-control form-select" formControlName="title" name="title">
                <option *ngIf="form.controls.title.dirty"></option>
                <option>Mr.</option>
                <option>Mrs.</option>
                <option>Ms.</option>
                <option>Dr.</option>
            </select>
        </div>
    </div>
    <div class="row mb-3">
        <label for="firstName" class="col-3 col-form-label"><strong>First Name:</strong></label>
        <div class="col-9">
            <input type="text" class="form-control" maxlength="30" id="firstName" formControlName="firstName"
                   name="firstName"
                   placeholder="First Name" required>
            <app-field-errors [field]="form.controls.firstName">
            </app-field-errors>
        </div>
    </div>
    <div class="row mb-3">
        <label for="middleName" class="col-sm-3 col-form-label"><strong>Middle Name:</strong></label>
        <div class="col-sm-9 ">
            <input type="text" class="form-control" maxlength="30" id="middleName" formControlName="middleName"
                   name="middleName"
                   placeholder="Middle Name">
            <app-field-errors [field]="form.controls.middleName">
            </app-field-errors>
        </div>
    </div>
    <div class="row mb-3">
        <label for="lastName" class="col-sm-3 col-form-label"><strong>Last Name:</strong></label>
        <div class="col-sm-9 ">
            <input type="text" class="form-control" maxlength="30" id="lastName" formControlName="lastName"
                   name="lastName"
                   placeholder="Last Name" required>
            <app-field-errors [field]="form.controls.lastName">
            </app-field-errors>
        </div>
    </div>
    <div class="row mb-3">
        <label for="phone" class="col-sm-3 col-form-label"><strong>Phone:</strong></label>
        <div class="col-sm-9 ">
            <input type="text" class="form-control" maxlength="20" id="phone" formControlName="phone" name="phone"
                   placeholder="Phone" required>
            <app-field-errors [field]="form.controls.phone">
            </app-field-errors>
        </div>
    </div>
    <div class="row mb-3">
        <label for="email" class="col-sm-3 col-form-label"><strong>Email:</strong></label>
        <div class="col-sm-9 ">
            <input type="text" class="form-control" maxlength="50" id="email" formControlName="email" name="email"
                   placeholder="Email" required>
            <app-field-errors [field]="form.controls.email">
            </app-field-errors>
        </div>
    </div>
    <div class="row mb-3">
        <label for="confirmEmail" class="col-sm-3 col-form-label"><strong>Confirm Email:</strong></label>
        <div class="col-sm-9 ">
            <input type="text" class="form-control" maxlength="50" id="confirmEmail" formControlName="confirmEmail"
                   name="confirmEmail" placeholder="Confirm Email" required>
            <app-field-errors [field]="form.controls.confirmEmail">
            </app-field-errors>
            <div *ngIf="form.errors?.confirmationDoesntMatch && !form.controls.confirmEmail.errors"
                 class="cross-validation-error-message alert alert-danger">
                Emails do not match
            </div>
        </div>
    </div>
</div>
