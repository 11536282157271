import {Component, OnInit} from '@angular/core';
import {DesignationService} from '../designation/designation.service';
import {DesignationImage, DesignationImageService} from '../designation/designation-image.service';
import {Designation} from '../designation/designation';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {GiftInformationNavService} from './gift-information-nav.service';
import {GiftService} from './gift.service';
import {first} from 'rxjs/operators';
import {OfficeContactMessage} from '../shared/office-contact/office-contact-message';
import {CustomerService} from '../customer/customer.service';
import {NotificationService} from '@uofu-uss/angular-util';

@Component({
  selector: 'app-gift-information',
  templateUrl: './gift-information.component.html',
  styleUrls: ['./gift-information.component.scss']
})
export class GiftInformationComponent implements OnInit {

  public designation: Designation;
  public errorSlide: string;
  imgUrl: string;

  constructor(private readonly route: ActivatedRoute,
              private readonly location: Location,
              private readonly designationService: DesignationService,
              private readonly giftService: GiftService,
              private readonly imageService: DesignationImageService,
              private readonly notificationService: NotificationService,
              readonly giftInformationNavService: GiftInformationNavService,
              private readonly customerService: CustomerService) {
  }

  get customTheme(): boolean {
    return this.customerService.requiresCustomization();
  }

  ngOnInit() {
    this.errorSlide = 'assets/images/gift-info/park-aerial.jpg';
    const id: number = +this.route.snapshot.paramMap.get('id');
    this.fetchDesignation(id);
    this.updateIdInStorage(id);
    this.giftInformationNavService.reset();
    this.route.data
      .subscribe((data: { defaultDesignationImage: DesignationImage, designationImage: DesignationImage }) => {
        const images = (data.designationImage.imageIds && data.designationImage.imageIds.length) ?
          data.designationImage.imageIds : data.defaultDesignationImage.imageIds;
        this.imgUrl = (images && images.length) ? `/ugive-services/image/data/${images[this.index(images.length)]}` : this.errorSlide;
      });
  }

  fetchDesignation(id: number) {
    return this.designationService.getDesignation(id)
      .pipe(first())
      .subscribe(designation => {
      this.designation = designation;
    }, () => {
      this.notificationService.addWarning('Unable to load needed gift information. ' +
        OfficeContactMessage.instance.contactIfErrorContinues);
    });
  }

  updateIdInStorage(id: number) {
    const currentObj = this.giftService.retrieveGift();
    currentObj.designationId = id;
    this.giftService.storeGift(currentObj);
  }

  /**
   * Provides a random integer from 0 to n-1
   */
  index(n: number): number {
    return Math.floor(Math.random() * n);
  }

}
