import {Injectable} from '@angular/core';
import {Gift} from './gift';
import {Contact} from '../contact-information/contact';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {GiftType} from './gift-type';

@Injectable({
  providedIn: 'root'
})
export class GiftService {
  private static readonly giftKey = 'ugive-gift-info';
  private static readonly giftServiceUrl = '/ugive-services/gift/';

  constructor(private readonly http: HttpClient) {

  }

  // This function adds a data item to session storage of browser
  storeGift(value: Gift) {
    sessionStorage.setItem(GiftService.giftKey, JSON.stringify(value));
  }

  /**
   * Save the gift on the server.
   */
  persistGift(gift: Gift):  Observable<Gift> {
    if (gift.id) {
      return this.http.post<Gift>(GiftService.giftServiceUrl + gift.id, gift);
    } else {
      return this.http.put<Gift>(GiftService.giftServiceUrl, gift);
    }
  }

  /**
   * retrieve gift from the server.
   * @returns {Gift}
   */
  retrieveGiftWithId(id: number): Observable<Gift> {
    return this.http.get<Gift>(GiftService.giftServiceUrl + id);
  }

  /**
   * retrieve the latest gift from the server.
   * @returns {Gift}
   */
  retrieveLatestGift(): Observable<Gift> {
    return this.http.get<Gift>(GiftService.giftServiceUrl + 'latest');
  }

  // This function retrieves the data item stored in session storage of browser
  retrieveGift(initialize = true): Gift {
    const obj = JSON.parse(sessionStorage.getItem(GiftService.giftKey));
    return obj ? obj : (initialize && this.initializeGift());
  }

  // This function clears a data item from session storage of browser
  clearGift(): void {
    sessionStorage.removeItem(GiftService.giftKey);
  }

  // This function checks if there is a gift stored in session storage of browser.
  hasGift(): boolean {
    const gift = this.retrieveGift(false);
    return gift && !!gift.designationId;
  }

  public initializeGift(): Gift {
    return {
      type: GiftType.ONE_TIME_GIFT,
      dedication: {},
      notification: {
        address: {}
      },
      contact: this.initializeContact()
    } as Gift;
  }

  initializeContact(): Contact {
    return {
      affiliation: {},
      name: {},
      address: {}
    } as Contact;
  }

}
