import {Component, Input, OnInit} from '@angular/core';
import {Gift} from '../../gift-information/gift';

@Component({
  selector: 'app-joint-gift',
  templateUrl: './joint-gift.component.html',
  styleUrls: ['./joint-gift.component.scss']
})
export class JointGiftComponent implements OnInit {

  @Input()
  gift: Gift;
  spouseName: string;

  constructor() {
  }

  ngOnInit() {
    this.spouseName = this.gift.contact.spouseName;
  }

}
