<div *ngIf="isDonationFromBusiness; else personalDonation">
  <h2 class="form-label-underline">Business Address</h2>
</div>
<ng-template #personalDonation>
  <h2 class="form-label-underline">Address</h2>
</ng-template>
<div [formGroup]="form">
  <div class="mb-3 row">
    <label for="address1" class="col-sm-3 col-form-label"><strong>Address 1:</strong></label>
    <div class="col-sm-9 ">
      <input type="text" class="form-control" maxlength="50" id="address1" formControlName="address1" name="address1"
             placeholder="Address 1" required>
      <app-field-errors [field]="form.controls.address1">
      </app-field-errors>
    </div>
  </div>
  <div class="mb-3 row">
    <label for="Address2" class="col-sm-3 col-form-label"><strong>Address 2:</strong></label>
    <div class="col-sm-9 ">
      <input type="text" class="form-control" maxlength="50" id="address2" formControlName="address2" name="address2"
             placeholder="Address 2">
      <app-field-errors [field]="form.controls.address2">
      </app-field-errors>
    </div>
  </div>
  <div class="mb-3 row">
    <label for="city" class="col-sm-3 col-form-label"><strong>City:</strong></label>
    <div class="col-sm-9 ">
      <input type="text" class="form-control" maxlength="50" id="city" formControlName="city" name="city"
             placeholder="City" required>
      <app-field-errors [field]="form.controls.city">
      </app-field-errors>
    </div>
  </div>
  <div class="mb-3 row">
    <label for="country" class="col-sm-3 col-form-label"><strong>Country:</strong></label>
    <div class="col-sm-5">
      <select id="country" class="form-control form-select" formControlName="country" name="country" required>
        <option [value]="country.shortCode" *ngFor="let country of countries">{{country.longName}}</option>
      </select>
      <app-field-errors [field]="form.controls.country">
      </app-field-errors>
    </div>
  </div>
  <div class="mb-3 row" *ngIf="provinces && provinces.length">
    <label for="province" class="col-sm-3 col-form-label"><strong>State/Province</strong></label>
    <div class="col-sm-5 ">
      <select id="province" class="form-control form-select" formControlName="province" name="province" required>
        <option [value]="province.code" *ngFor="let province of provinces">{{province.name}}</option>
      </select>
      <app-field-errors [field]="form.controls.province">
      </app-field-errors>
    </div>
  </div>
  <div class="mb-3 row">
    <label for="zipCode" class="col-sm-3 col-form-label"><strong>ZIP/Postal Code:</strong></label>
    <div class="col-sm-5 ">
      <input type="text" class="form-control" maxlength="15" id="zipCode" formControlName="zipCode"
             name="zipCode" placeholder="ZIP/Postal Code" required>
      <app-field-errors [field]="form.controls.zipCode">
      </app-field-errors>
    </div>
  </div>
</div>
