import {GiftInformationNavService} from './gift-information-nav.service';
import {GiftService} from './gift.service';
import {Gift} from './gift';
import {Router} from '@angular/router';
import {NotificationService} from '@uofu-uss/angular-util';

export abstract class BaseGiftInformation {

  protected constructor(protected giftService: GiftService,
                        private giftInformationNavService: GiftInformationNavService,
                        protected notificationService: NotificationService,
                        protected router: Router) {
  }

  next(gift: Gift) {
    this.notificationService.clear();
    if (this.giftInformationNavService.isLastPage(this.giftInformationNavService.currentView, gift)) {
      this.router.navigate(['/', 'contact-information'], {queryParamsHandling: 'preserve'});
    } else {
      this.giftInformationNavService.currentView =
        this.giftInformationNavService.nextView(this.giftInformationNavService.currentView, gift);
    }
  }

  previous() {
    this.notificationService.clear();
    this.giftInformationNavService.currentView =
      this.giftInformationNavService.previousView(this.giftInformationNavService.currentView);
  }

}
