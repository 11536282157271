<div *appIfFieldErrors="field">
  <div class="alert alert-danger">
    <div *ngIf="field.errors.date">
      <ng-template #dateDefaultTemplate>
        <span class="dateErrorMessage">Not a valid date</span>
      </ng-template>
      <ng-container *ngTemplateOutlet="dateTemplate ? dateTemplate: dateDefaultTemplate">
      </ng-container>
    </div>
    <div *ngIf="field.errors.email">
      <ng-template #emailDefaultTemplate>
        <span class="emailErrorMessage">Not a valid email address</span>
      </ng-template>
      <ng-container *ngTemplateOutlet="emailTemplate ? emailTemplate: emailDefaultTemplate">
      </ng-container>
    </div>
    <div *ngIf="field.errors.max">
      <ng-template #maxDefaultTemplate>
        <span class="maxErrorMessage">Exceeds maximum value of {{field.errors.max.max}}</span>
      </ng-template>
      <ng-container *ngTemplateOutlet="maxTemplate ? maxTemplate: maxDefaultTemplate">
      </ng-container>
    </div>
    <div *ngIf="field.errors.maxlength">
      <ng-template #maxLengthDefaultTemplate>
        <span class="maxLengthErrorMessage">Exceeds maximum length of {{field.errors.maxlength.requiredLength}}</span>
      </ng-template>
      <ng-container *ngTemplateOutlet="maxLengthTemplate ? maxLengthTemplate: maxLengthDefaultTemplate">
      </ng-container>
    </div>
    <div *ngIf="field.errors.modulus">
      <ng-template #modulusDefaultTemplate>
        <span class="modulusErrorMessage">Not a valid amount. Must be multiple of {{field.errors.modulus.requiredModulus}}</span>
      </ng-template>
      <ng-container *ngTemplateOutlet="modulusTemplate ? modulusTemplate: modulusDefaultTemplate">
      </ng-container>
    </div>
    <div *ngIf="field.errors?.min">
      <ng-template #minDefaultTemplate>
        <span class="minErrorMessage">Does not meet minimum value of {{field.errors.min.min}}</span>
      </ng-template>
      <ng-container *ngTemplateOutlet="minTemplate ? minTemplate: minDefaultTemplate">
      </ng-container>
    </div>
    <div *ngIf="field.errors.minlength">
      <ng-template #minLengthDefaultTemplate>
        <span class="minLengthErrorMessage">Does not meet minimum length of {{field.errors.minlength.requiredLength}}</span>
      </ng-template>
      <ng-container *ngTemplateOutlet="minLengthTemplate ? minLengthTemplate: minLengthDefaultTemplate">
      </ng-container>
    </div>
    <div *ngIf="field.errors.number">
      <ng-template #numberDefaultTemplate>
        <span class="numberErrorMessage">Not a valid number</span>
      </ng-template>
      <ng-container *ngTemplateOutlet="numberTemplate ? numberTemplate: numberDefaultTemplate">
      </ng-container>
    </div>
    <div *ngIf="field.errors.parse">
      <ng-template #parseDefaultTemplate>
        <span class="parseErrorMessage">Required Or Some Such</span>
      </ng-template>
      <ng-container *ngTemplateOutlet="parseTemplate ? parseTemplate: parseDefaultTemplate">
      </ng-container>
    </div>
    <div *ngIf="field.errors.pattern">
      <ng-template #patternDefaultTemplate>
        <span class="patternErrorMessage">Does not match expected pattern.</span>
      </ng-template>
      <ng-container *ngTemplateOutlet="patternTemplate ? patternTemplate: patternDefaultTemplate">
      </ng-container>
    </div>
    <div *ngIf="field.errors.precision">
      <ng-template #precisionDefaultTemplate>
        <span class="precisionErrorMessage">Not a valid amount.</span>
      </ng-template>
      <ng-container *ngTemplateOutlet="precisionTemplate ? precisionTemplate: precisionDefaultTemplate">
      </ng-container>
    </div>
    <div *ngIf="field.errors.required">
      <ng-template #requiredDefaultTemplate>
        <span class="requiredErrorMessage">Required</span>
      </ng-template>
      <ng-container *ngTemplateOutlet="requiredTemplate ? requiredTemplate: requiredDefaultTemplate">
      </ng-container>
    </div>
    <div *ngIf="field.errors.url">
      <ng-template #urlDefaultTemplate>
        <span class="urlErrorMessage">Not a valid URL</span>
      </ng-template>
      <ng-container *ngTemplateOutlet="urlTemplate ? urlTemplate: urlDefaultTemplate">
      </ng-container>
    </div>
  </div>
</div>
