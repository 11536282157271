<div class="gift-form">
  <h3 class="gift-title mb-4">Gift Summary</h3>
  <div class="row justify-content-center">
    <img *ngIf="!customTheme" class="img-fluid w-auto pe-lg-5" src="assets/images/imagine-new-heights-square.png"/>
    <img *ngIf="customTheme" class="img-fluid w-auto pe-lg-5" src="assets/images/gratitude.png"/>
  </div>
  <div class="card-text mt-4">
    <p *ngIf="designation?.name">
      <strong>Designation:</strong><br>
      {{designation.name}}
    </p>
    <p *ngIf="giftInfo?.amount">
      <strong>Amount:</strong><br>
      {{giftInfo.amount | currency}}
    </p>
    <p *ngIf="giftInfo?.specialInstructions" class="wrap-word">
      <strong>Special Instructions:</strong><br>
      {{giftInfo.specialInstructions}}
    </p>
  </div>
</div>
