import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {BaseContactInfoComponent} from '../base-contact-info/base-contact-info.component';

@Component({
  selector: 'app-uofu',
  templateUrl: './affiliation.component.html',
  styleUrls: ['./affiliation.component.scss'],
  inputs: ['contact'],
})
export class AffiliationComponent extends BaseContactInfoComponent implements OnInit {

  constructor(private readonly fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.form = this.fb.group({
      alum: [this.contact.affiliation.alum, []],
      classOf: [this.contact.affiliation.classOf, [Validators.maxLength(20)]],
      degrees: [this.contact.affiliation.degrees, [Validators.maxLength(50)]],
      facultyOrStaff: [this.contact.affiliation.facultyOrStaff, []],
      emplid: [this.contact.affiliation.emplid, [Validators.maxLength(20)]],
      student: [this.contact.affiliation.student, []],
      studentId: [this.contact.affiliation.emplid, [Validators.maxLength(20)]],
      friend: [this.contact.affiliation.friend, []]
    });
  }

  save() {
    this.contact.affiliation.alum = this.form.controls.alum.value;
    this.contact.affiliation.classOf = this.contact.affiliation.alum ? this.form.controls.classOf.value : '';
    this.contact.affiliation.degrees = this.contact.affiliation.alum ? this.form.controls.degrees.value : '';
    this.contact.affiliation.facultyOrStaff = this.form.controls.facultyOrStaff.value;
    this.contact.affiliation.student = this.form.controls.student.value;
    this.contact.affiliation.emplid =
      (this.contact.affiliation.student || this.contact.affiliation.facultyOrStaff) ? this.form.controls.emplid.value : '';
    this.contact.affiliation.friend = this.form.controls.friend.value;
  }

}
