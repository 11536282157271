import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Country} from './country';
import {Province} from './province';
import {Link} from './link';
import {Observable, of, switchMap} from 'rxjs';
import {ConfigService} from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class GeoService {
    private readonly http =  inject(HttpClient);
    private readonly configService = inject(ConfigService);

  countries(): Observable<Country[]> {
    return this.configService.config$
        .pipe(switchMap(config => this.http.get<Country[]>(`${config.geo_url}/country`)));
  }

  provinces(country: Country): Observable<Province[]> {
   const provinceLink: Link = country.links.filter(link => link.rel === 'provinces').pop();
   if (provinceLink) {
      return this.http.get<Province[]>(provinceLink.href);
    }
   return of([]);
  }

}
