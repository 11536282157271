<h2 class="form-label-underline">U of U Affiliation</h2>
<fieldset>
    <div class="mb-3 row" [formGroup]="form">
        <label class="col-sm-3 col-form-label"><strong>Check all that apply:</strong></label>
        <div class="col-sm-9 ps-4">
            <div class="row justify-content-between mb-1">
                <div class="col-5 form-check">
                    <input class="form-check-input" type="checkbox" name="alum" id="alum"
                           formControlName="alum">
                    <label class="form-check-label" for="alum">
                        Alum
                    </label>
                </div>
                <div *ngIf="!!form.controls.alum.value" class="me-0 col-7">
                    <div class="row">
                        <div class="col-6 pe-1">
                            <input name="classOf" id="classOf" formControlName="classOf"
                                   class="form-control form-control-sm" maxlength="20" type="text"
                                   placeholder="Class Of">
                            <app-field-errors [field]="form.controls.classOf">
                            </app-field-errors>
                        </div>
                        <div class="col-6 px-0">
                            <input name="degrees" id="degrees" formControlName="degrees"
                                   class="form-control form-control-sm" maxlength="50" type="text"
                                   placeholder="Degrees">
                            <app-field-errors [field]="form.controls.degrees">
                            </app-field-errors>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-between mb-1">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" name="facultyOrStaff"
                           id="facultyOrStaff"
                           formControlName="facultyOrStaff">
                    <label class="form-check-label" for="facultyOrStaff">
                        University Faculty/Staff
                    </label>
                </div>
            </div>
            <div class="row justify-content-between mb-1">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" name="student" id="student"
                           formControlName="student">
                    <label class="form-check-label" for="student">
                        Student
                    </label>
                </div>
            </div>
            <div class="row justify-content-between mb-1">
                <div class="form-check" *ngIf="!!form.controls.facultyOrStaff.value || !!form.controls.student.value">
                    <label class="form-check-label" for="emplid">
                        Student or Employee ID
                    </label>
                    <input name="emplid" id="emplid" formControlName="emplid" class="form-control form-control-sm"
                           maxlength="20" type="text" placeholder="Student or Emplid">
                    <app-field-errors [field]="form.controls.emplid">
                    </app-field-errors>
                </div>
            </div>
            <div class="row">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" name="friendBox" id="friendBox"
                           formControlName="friend">
                    <label class="form-check-label" for="friendBox">
                        Friend
                    </label>
                </div>
            </div>
        </div>
    </div>
</fieldset>
