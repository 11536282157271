<div class="row justify-content-center mt-5">
  <div class="col-lg-6 home-text align-self-center pb-5 pb-lg-1">
    <h1 class="text-condensed imagine-new-text">
      Every gift is gratefully received and together we can Imagine New Heights!
    </h1>
  </div>
  <div class="col-lg-4">
    <app-fund-select></app-fund-select>
  </div>
</div>
