import {Component} from '@angular/core';
import {HomeStateService} from '../home-state.service';

@Component({
  selector: 'app-fund-select',
  templateUrl: './fund-select.component.html',
  styleUrls: ['./fund-select.component.scss']
})
export class FundSelectComponent {


  constructor(private readonly homeStateService: HomeStateService) {
  }

  get choosingCategory(): boolean {
    return this.homeStateService.choosingCategory;
  }

  toggleChoosingCategory() {
    this.homeStateService.choosingCategory = !this.choosingCategory;
  }

  get choosingGreatestNeeds(): boolean {
    return this.homeStateService.choosingGreatestNeeds;
  }

  toggleChoosingGreatestNeeds() {
    this.homeStateService.choosingGreatestNeeds = !this.choosingGreatestNeeds;
  }

  get choosingCovid(): boolean {
    return this.homeStateService.choosingCovid;
  }

  toggleChoosingCovid() {
    this.homeStateService.choosingCovid = !this.choosingCovid;
  }

}
