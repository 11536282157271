<div class="gift-form">
    <h3 class="gift-title mb-4"><strong>Make Your Gift</strong></h3>
    <form [formGroup]="giftAmountForm" (ngSubmit)="updateAndProceed()">

        <div class="mb-3">
            <div>
                <label>
                    How Much Would You Like to Give?
                </label>
            </div>
            <div class="card-text text-center">
                <div class="row">
                    <div class="col-sm-12 col-lg-6">
                        <div class="input-group">
                            <span class="input-group-text">$</span>
                            <label for="amount" class="visually-hidden">Gift Amount</label>
                            <input type="number" name="amount" id="amount" class="form-control" formControlName="amount"
                                   min="1"
                                   aria-label="Amount" required>
                        </div>
                        <app-field-errors [field]="giftAmountForm.controls.amount">
                        </app-field-errors>
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-3 mt-4">
            <div>
                <label>
                    Would You Like to Dedicate Your Gift?
                </label>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="input-group">
                        <div class="me-1">
                            <select id="dedicationDropdown" class="form-control form-select" formControlName="dedicationType"
                                    name="dedicationType">
                                <option [ngValue]="null">No</option>
                                <option value="MEMORY">In Memory of</option>
                                <option value="HONOR">In Honor of</option>
                            </select>
                        </div>
                        <input type="text" class="form-control" id="name" name="name" maxlength="100"
                               formControlName="name"
                               placeholder="Name">
                    </div>
                    <app-field-errors [field]="giftAmountForm.controls.name">
                    </app-field-errors>
                </div>
            </div>
        </div>

        <div class="mb-3 mt-4">
            <div>
                <label for="specialInstructions">
                    Special Instructions Regarding Your Gift:
                </label>
            </div>
            <div>
              <textarea class="form-control" maxlength="2000" id="specialInstructions"
                        formControlName="specialInstructions"
                        name="specialInstructions" rows="5" [required]="specialInstructionsRequired()"></textarea>
                <app-field-errors [field]="giftAmountForm.controls.specialInstructions">
                </app-field-errors>
            </div>
        </div>

        <div *ngIf="isDedicatingGift" class="form-check mb-4 mt-4">
            <input class="form-check-input" formControlName="notifySomeone" type="checkbox" id="notifySomeone">
            <label class="form-check-label" for="notifySomeone">
                I Would Like to Notify Someone of This Gift.
            </label>
        </div>
        <app-field-errors [field]="giftAmountForm.controls.notifySomeone">
        </app-field-errors>

        <div class="row center-buttons m-3">
            <button type="submit" class="btn btn-primary w-auto m-1">CONTINUE &gt;</button>
        </div>
    </form>
</div>
