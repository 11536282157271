import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormValidationService} from './form-validation.service';
import { IfFieldErrorsDirective } from './if-field-errors.directive';
import {FieldErrorsComponent} from './field-errors/field-errors.component';
import {NotificationModule} from '@uofu-uss/angular-util';
import {ValidationModule as UssValidationModule} from '@uofu-uss/angular-util';

@NgModule({
  imports: [
    CommonModule,
    NotificationModule,
    UssValidationModule,
  ],
  declarations: [IfFieldErrorsDirective, FieldErrorsComponent],
  exports: [IfFieldErrorsDirective, FieldErrorsComponent],
  providers: [FormValidationService]
})
export class ValidationModule {
}
