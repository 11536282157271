<br>
<div class="row ms-3 me-3">
  <div class="col-12 col-lg-3">
    <app-left-summary [receipt]="receipt"></app-left-summary>
  </div>
  <div class="fund-info col-12 col-lg-9">
    <h1>{{title}}</h1>
    <p class="pb-2">{{message}}</p>
    <app-gift-summary [gift]="gift" [designationId]="designationId" [receipt]="receipt"></app-gift-summary>
    <app-my-info [receipt]="receipt" [gift]="gift"></app-my-info>
    <app-business-donation *ngIf="contact.donationFromBusiness" [receipt]="receipt"
                           [gift]="gift"></app-business-donation>
    <app-joint-gift *ngIf="contact.spouseName" [gift]="gift"></app-joint-gift>
    <app-matching-company
      *ngIf="!contact.donationFromBusiness && contact.matchingCompany && contact.matchingCompany.length > 0"
      [gift]="gift">
    </app-matching-company>
    <div *ngIf="!receipt && uccpConfiguration" class="text-center pt-5">
      <app-make-payment [uccpConfiguration]="uccpConfiguration"></app-make-payment>
      <button class="btn btn-primary m-2" type="button" (click)="confirmGift()">
        CONFIRM YOUR CONTRIBUTION
      </button>
    </div>
  </div>
</div>
