import {CanActivate, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {NotificationService} from '@uofu-uss/angular-util';

@Injectable()
export class ActiveGiftGuard implements CanActivate {

  constructor(private readonly router: Router,
              private readonly notificationService: NotificationService) {
  }

  canActivate(): boolean {
    if (sessionStorage.getItem('ugive-gift-info') != null) {
      return true;
    } else {
      this.notificationService.keepAlive(true);
      this.notificationService.addWarning({title: 'Your session has expired', body: ''});
      this.router.navigate(['']);
      return false;
    }
  }
}
