
<div>
  <div>
    <div [formGroup]="categoryForm">
      <select id="selected" name="selected" formControlName="category" class="form-control form-select">
        <option [ngValue]="combined" *ngFor="let combined of combinedList">{{combined.name}}</option>
      </select>
    </div>
  </div>
  <div class="mt-1 mb-1">
    <app-by-category-selection *ngIf="childDesignations && childCategories && !loading" [categories]="childCategories" [designations]="childDesignations"></app-by-category-selection>
  </div>
  <div *ngIf="!!loading" class="mt-3 mb-3">
    <app-loader [loading]="!!loading"></app-loader>
  </div>
  <div class="row center-buttons" *ngIf="!childDesignations">
    <button class="btn btn-primary w-auto m-2" (click)="goBack()">BACK</button>
    <button class="btn w-auto m-2" [ngClass]="{'btn-primary': designationId, 'btn-secondary': !designationId}"
            [disabled]="!designationId" routerLink="/designation/{{designationId}}">CONTINUE
    </button>
  </div>
</div>
