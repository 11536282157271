import {Injectable} from '@angular/core';

export enum KnownCustomers {
    HuntsmanMentalHealthInstitute = 'hmhi',
}

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    constructor() {
    }

    requiresCustomization(): boolean {
        // const id = window.location.pathname.includes('3465');
        const cust = this.getCustomer();
        return cust && !!Object.values(KnownCustomers).find(value => value === cust);
    }

    hasCustomer(knownCustomer: KnownCustomers): boolean {
        return this.getCustomer() === knownCustomer;
    }

    getCustomer(): string {
        return new URLSearchParams(window.location.search).get('customer') || null;
    }

}
