<div class="gift-form">
      <h3 class="gift-title mb-4">Contact Information For Notification</h3>
      <form [formGroup]="notificationForm" (ngSubmit)="updateAndProceed()">
        <div class="row mb-3">
          <label for="notifyName" class="col-12 col-form-label">Name</label>
          <div class="col-12">
            <input type="text" class="form-control" id="notifyName" name="notifyName" maxlength="100" formControlName="name" placeholder="Name" required>
            <app-field-errors [field]="notificationForm.controls.name">
            </app-field-errors>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="address1" class="col-12 col-form-label">Address 1</label>
          <div class="col-12">
            <input type="text" class="form-control" id="address1" maxlength="100" formControlName="address1" name="address1"
                   placeholder="Address 1" required>
            <app-field-errors [field]="notificationForm.controls.address1">
            </app-field-errors>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="address2" class="col-12 col-form-label">Address 2</label>
          <div class="col-12">
            <input type="text" class="form-control" id="address2" maxlength="100" formControlName="address2"
                   name="address2"
                   placeholder="Address 2">
            <app-field-errors [field]="notificationForm.controls.address2">
            </app-field-errors>
          </div>
        </div>
        <div class="mb-3 row">
        <label for="city" class="col-12 col-form-label">City</label>
          <div class="col-12 ">
            <input type="text" class="form-control" id="city" maxlength="100" formControlName="city" name="city"
                   placeholder="City" required>
            <app-field-errors [field]="notificationForm.controls.city">
            </app-field-errors>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="country" class="col-12 col-form-label">Country</label>
          <div class="col-12 ">
            <select id="country" class="form-control form-select" formControlName="country" name="country" required>
              <option [value]="country.shortCode" *ngFor="let country of countries">{{country.longName}}</option>
            </select>
            <app-field-errors [field]="notificationForm.controls.country">
            </app-field-errors>
          </div>
        </div>
        <div class="mb-3 row" *ngIf="provinces && provinces.length">
          <label for="province" class="col-12 col-form-label">State/Province</label>
          <div class="col-12">
            <select id="province" class="form-control form-select" formControlName="province" name="province" required>
              <option [value]="province.code" *ngFor="let province of provinces">{{province.name}}</option>
            </select>
            <app-field-errors [field]="notificationForm.controls.province">
            </app-field-errors>
          </div>
        </div>
        <div class="mb-3 row mb-4">
          <label for="postalCode" class="col-sm-12 col-form-label">Zip / Postal Code</label>
          <div class="col-12">
            <input type="text" class="form-control" id="postalCode" maxlength="20" formControlName="zipCode"
                   name="zipCode" placeholder="Zip/Postal Code" required>
            <app-field-errors [field]="notificationForm.controls.zipCode">
            </app-field-errors>
          </div>
        </div>

        <div class="row center-buttons m-3">
          <button class="btn btn-primary w-auto m-1" type="button" (click)="updateNotifySomeoneInfo();previous()">
            &lt; Back
          </button>
          <button class="btn btn-primary w-auto m-1" type="submit">
            Continue &gt;
          </button>
        </div>
      </form>
    </div>
