import {Component, OnInit} from '@angular/core';
import {DesignationService} from '../../designation/designation.service';
import {HomeStateService} from '../home-state.service';
import {Designation} from '../../designation/designation';
import {OfficeContactMessage} from '../../shared/office-contact/office-contact-message';
import {NotificationService} from '@uofu-uss/angular-util';

@Component({
  selector: 'app-by-need',
  templateUrl: './by-need.component.html',
  styleUrls: ['./by-need.component.scss']
})
export class ByNeedComponent implements OnInit {

  designationsByNeed: Designation[];

  constructor(private readonly designationService: DesignationService,
              private readonly homeStateService: HomeStateService,
              private readonly notificationService: NotificationService) {
  }

  ngOnInit() {
    this.notificationService.clear();
    this.fetchGreatestNeedDesignations();
  }

  goBack() {
    this.homeStateService.resetState();
  }

  public fetchGreatestNeedDesignations() {
    this.designationService.getGreatestNeed().subscribe(designations => {
      this.designationsByNeed = (designations || []).filter(d => !!d);
      if (this.designationsByNeed.length !== designations.length) {
        this.notify();
      }
    }, () => this.notify());
  }

  private notify() {
    this.notificationService.addWarning('An error prevented the page from working. ' +
      OfficeContactMessage.instance.contactIfErrorContinues);
    this.goBack();
  }

}
