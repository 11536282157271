import {Component, Input} from '@angular/core';
import {OfficeContact} from '../../shared/office-contact/office-contact';
import {CustomerService} from '../../customer/customer.service';

@Component({
  selector: 'app-left-summary',
  templateUrl: './left-summary.component.html',
  styleUrls: ['./left-summary.component.scss']
})
export class LeftSummaryComponent {

  @Input() officeContact: OfficeContact = OfficeContact.Instance;
  @Input() receipt: boolean;

  constructor(private readonly customerService: CustomerService) {
  }

  get customTheme(): boolean {
    return this.customerService.requiresCustomization();
  }

}
