import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {CustomerService, KnownCustomers} from './customer/customer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private _onHomePage = false;
  private eventSubscription: Subscription;

  constructor(private readonly router: Router,
              private readonly customerService: CustomerService) {
  }

  ngOnInit(): void {
    this.eventSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const navEnd = event as NavigationEnd;
        this.onHomePage = navEnd.urlAfterRedirects === '/';
      }
    });
  }

  ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

  get onHomePage() {
    return this._onHomePage;
  }

  set onHomePage(onPage: boolean) {
    this._onHomePage = onPage;
  }

  get customTheme(): boolean {
    return this.customerService.requiresCustomization();
  }

  get useHuntsmanMentalHealthInstituteTheme(): boolean {
    return this.customerService.hasCustomer(KnownCustomers.HuntsmanMentalHealthInstitute);
  }

  onActivate() {
    if (window.scrollTo) {
      window.scrollTo(0, 0);
    }
  }

}
