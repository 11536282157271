import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

export interface DesignationImage {
  id: number;
  imageIds: number[];
}

@Injectable({
  providedIn: 'root'
})

export class DesignationImageService {

  constructor(private http: HttpClient) { }

  getImageIds(id: string | number): Observable<DesignationImage> {
    return this.http.get<DesignationImage>('/ugive-services/designation-image/' + id);
  }

}
