import {OfficeAddress} from './office-address';

export class OfficeContact {

  private static _instance: OfficeContact;

  private readonly _organization = 'The University of Utah';
  private readonly _phone = '801-581-3720';
  private readonly _tollFree = '800-716-0377';
  private readonly _email = 'ugive@admin.utah.edu';
  private readonly _address = OfficeAddress.Instance;

  get organization(): string {
    return this._organization;
  }

  get phone() {
    return this._phone;
  }

  get email() {
    return this._email;
  }

  get tollFree() {
    return this._tollFree;
  }

  get address(): OfficeAddress {
    return this._address;
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

}
