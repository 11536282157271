import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs/index';
import {HttpClient} from '@angular/common/http';

export interface UccpConfiguration {
  paymentUrl: string;
  account: string;
}


@Injectable({
  providedIn: 'root'
})
export class UccpService {

  constructor(private http: HttpClient) { }

  getUccpInfo(): Observable <UccpConfiguration> {
    return this.http.get<UccpConfiguration>('/ugive-services/uccp-configuration');
  }

}
