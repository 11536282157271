import {Component, OnInit} from '@angular/core';
import {INotification, NotificationService} from '@uofu-uss/angular-util';

@Component({
  selector: 'app-global-alert',
  templateUrl: './global-alert.component.html',
  styleUrls: ['./global-alert.component.scss']
})
export class GlobalAlertComponent {

  constructor(private notificationService: NotificationService) {
  }

  getAlerts() {
    return this.notificationService.notifications;
  }

  removeAlertFromAlerts(notification: INotification) {
    this.notificationService.removeNotification(notification.id);
  }
}
