import {Component} from '@angular/core';
import {HomeStateService} from '../../home/home-state.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  public isCollapsed = true;

  constructor(private readonly homeStateService: HomeStateService) {
  }

  resetHome() {
    this.homeStateService.resetState();
  }

}
