<div class="card">
  <div class="card-body text-center">
    <img class="img-fluid" src="assets/images/imagine-new-heights.png"/>

    <div *ngIf="!choosingCategory && !choosingGreatestNeeds && !choosingCovid">
      <br>
      <h2 class="h3 card-title text-center text-condensed mt-3">Browse Funds By:</h2>
      <hr>
      <p class="card-text text-center d-grid gap-1">
        <button id="temporaryDesignation" class="btn btn-primary" type="button"
                (click)="toggleChoosingCovid()">COVID-19 Emergency Response
        </button>
        <br>
        <button id="greatestNeedButton" class="btn btn-primary" type="button"
                (click)="toggleChoosingGreatestNeeds()">Greatest Need
        </button>
        <br>
        <button id="categoryButton" class="btn btn-primary" type="button" (click)="toggleChoosingCategory()">
          Category
        </button>
        <br>
        <button id="aToZButton" class="btn btn-primary" type="button" routerLink="/designations-list"
                routerLinkActive="active">
          A-Z List
        </button>
      </p>
    </div>
    <app-by-category *ngIf="choosingCategory"></app-by-category>
    <app-by-need *ngIf="choosingGreatestNeeds"></app-by-need>
    <app-by-covid *ngIf="choosingCovid"></app-by-covid>
  </div>
</div>
