import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DesignationsListComponent} from './designation/designation-list/designations-list.component';
import {HomeComponent} from './home/home.component';
import {GiftInformationComponent} from './gift-information/gift-information.component';
import {ContactInformationComponent} from './contact-information/contact-information.component';
import {ConfirmationMainComponent} from './confirmation-receipt/confirmation-main/confirmation-main.component';
import {ReceiptMainComponent} from './confirmation-receipt/receipt-main/receipt-main.component';
import {ActiveGiftGuard} from './active-gift.service';
import {
  DefaultDesignationImageResolverService,
  DesignationImageResolverService
} from './designation/designation-image-resolver.service';
import {ReceiptCustomerGuard} from './customer/receipt-customer.guard';
import {CustomerNotAllowedGuard} from './customer/customer-not-allowed.guard';
import {ValidCustomerGuard} from './customer/valid-customer.guard';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [CustomerNotAllowedGuard]
  },
  {
    path: 'designations-list',
    component: DesignationsListComponent,
    canActivate: [CustomerNotAllowedGuard]
  },
  {
    path: 'designation/:id',
    component: GiftInformationComponent,
    canActivate: [ValidCustomerGuard],
    resolve: {
      defaultDesignationImage: DefaultDesignationImageResolverService,
      designationImage: DesignationImageResolverService
    }
  },
  {
    path: 'contact-information',
    component: ContactInformationComponent,
    canActivate: [ActiveGiftGuard, ValidCustomerGuard]
  },
  {
    path: 'confirmation',
    component: ConfirmationMainComponent,
    canActivate: [ActiveGiftGuard, ValidCustomerGuard],
  },
  {
    path: 'receipt',
    component: ReceiptMainComponent,
    canActivate: [ReceiptCustomerGuard]
  },
  {
    path: '**',
    redirectTo: ''
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
