<div class="container">
    <br>
    <div class="row">
        <div class="col-10 align-self-center">
            <h1 class="text-black text-condensed p-1 mb-0">
                A-Z Index
            </h1>
        </div>
    </div>
    <br>

    <div class="row border-custom col-10 offset-1 mb-5">
        <div class="col">
            <h2 class="h4 mb-3">
                Don't See What You're Looking For?
            </h2>
            <p>
                Create a custom gift and describe the fund you'd like to donate to and we can tailor the gift to suit
                your special
                instructions.
            </p>
            <button id="customButton" class="btn btn-dark" type="button"
                    routerLink="/designation/{{designationService.NONE_OF_THE_ABOVE_ID}}"
                    routerLinkActive="active">
                Create Custom Gift
            </button>
        </div>
    </div>


    <app-alphabet-nav-bar></app-alphabet-nav-bar>


    <div class="h5 text-center">
        <app-loader [loading]="!allDesignations && !error"></app-loader>
    </div>

    <ul class="list-unstyled mt-5" *ngIf="allDesignations">
        <li *ngFor="let letter of fetchLetters()">
            <app-designation-groups [designationLetter]=letter
                                    [designationList]=allDesignations></app-designation-groups>
            <br>
        </li>
    </ul>

</div>

<app-scroll-to-top></app-scroll-to-top>

