import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlphabetService {

  letters: String[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K',
    'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

  constructor() {
  }

  getLetters(): String[] {
    return this.letters;
  }

}
