import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Category} from '../category';
import {HttpClient} from '@angular/common/http';
import {Designation} from '../../designation/designation';
import {DesignationService} from '../../designation/designation.service';
import {HomeStateService} from '../home-state.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {CategoryService} from '../../category/category.service';
import {first} from 'rxjs/operators';
import {OfficeContactMessage} from '../../shared/office-contact/office-contact-message';
import {NotificationService} from '@uofu-uss/angular-util';

export class CombinedId {
  id: string;
  name: string;
  isCategory: boolean;
}

@Component({
  selector: 'app-by-category-selection',
  templateUrl: './by-category-selection.component.html',
  styleUrls: ['./by-category-selection.component.scss'],
})
export class ByCategorySelectionComponent implements OnInit, OnDestroy {

  @Input()
  categories: Array<Category> = [];
  @Input()
  designations: Array<Designation> = [];

  // The selected designation ID. This is often null.
  designationId: string;
  // The list of categories and designations combined.
  combinedList: Array<CombinedId> = [];

  childCategories: Array<Category>;
  childDesignations: Array<Designation>;

  categoryForm: FormGroup;
  categorySubscription: Subscription;

  // The number of loading requests being made to the server
  loading = 0;

  constructor(private readonly http: HttpClient,
              private readonly designationService: DesignationService,
              private readonly categoryService: CategoryService,
              private readonly homeStateService: HomeStateService,
              private readonly fb: FormBuilder,
              private readonly notificationService: NotificationService) {
  }

  ngOnInit() {
    this.categoryForm = this.fb.group({
      category: [null],
    });

    this.categorySubscription =
      this.categoryForm.controls.category.valueChanges.subscribe((value) => {
        this.combinedList = this.combinedList.filter((combinedId) => !!combinedId.id);
        this.changeSelection(value);
      });

    this.initializeCombinedList();
  }

  ngOnDestroy() {
    if (this.categorySubscription) {
      this.categorySubscription.unsubscribe();
    }
  }

  initializeCombinedList() {
    this.categories.forEach((c) => {
      this.combinedList.push({id: c.id, name: c.name, isCategory: true});
    });
    this.designations.forEach((d) => {
      this.combinedList.push({id: d.id, name: d.name, isCategory: false});
    });
    this.combinedList.sort((combinedId1, combinedId2) => {
      if (combinedId1.name > combinedId2.name) {
        return 1;
      } else if (combinedId1.name < combinedId2.name) {
        return -1;
      }
      return 0;
    });
    this.combinedList.unshift({id: null, name: null, isCategory: false});
  }

  goBack() {
    this.homeStateService.resetState();
  }

  private changeSelection(combined: CombinedId) {
    this.clearSelections();
    if (combined.isCategory) {
      this.categoryService.fetchCategories(combined.id)
        .pipe(first())
        .subscribe(this.handleLoadedChildCategories, this.handleLoadFailure);
      this.loading++;
      this.designationService.getDesignationsByCategory(combined.id)
        .pipe(first())
        .subscribe(this.handleLoadedChildDesignations, this.handleLoadFailure);
      this.loading++;
    } else {
      // this delivers chosen designation id to its parent component.
      this.designationId = combined.id;
    }
  }

  private clearSelections() {
    this.loading = 0;
    this.childCategories = null;
    this.childDesignations = null;
    this.designationId = null;
  }

  private handleLoadedChildCategories = (categories: Category[]): void => {
    this.childCategories = categories || [];
    this.loading--;
  }

  private handleLoadedChildDesignations = (designations: Designation[]): void => {
    this.childDesignations = [];
    this.loading--;
    if (designations) {
      this.childDesignations = designations.filter(des => !this.designationService.isDisabledOrExpired(des));
    }
  }

  private handleLoadFailure = (): void => {
    this.loading--;
    this.notificationService.addWarning('An error prevented the page from working. ' +
      OfficeContactMessage.instance.contactIfErrorContinues);
    this.goBack();
  }
}
