<div class="w-100 bg-primary py-2">
    <app-skip-link></app-skip-link>
    <header role="banner" class="container-fluid">
        <div class="row">
            <div class="col p-0">
                <a href="https://www.utah.edu" target="_blank" class="align-self-center">
                    <img src="https://templates.utah.edu/apps/v1/_images/logo/ulogo.png" class="img-fluid logo mx-1"/>
                </a>
                <a (click)="resetHome()" routerLink="/"
                   class="align-self-center title display-4 align-middle">Give </a>
            </div>
        </div>
    </header>
</div>

<div class="w-100 bg-dark">
    <nav class="navbar navbar-expand-sm navbar-dark container-fluid">
        <div class="mx-auto small">
            <button class="navbar-toggler mx-3"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNavDropdown"
                    (click)="isCollapsed = !isCollapsed"
                    aria-controls="navbarNavDropdown"
                    [attr.aria-expanded]="!isCollapsed"
                    aria-label="Toggle navigation">
                <i class="fa fa-bars" style="color: white"></i>
            </button>
            <div class="collapse navbar-collapse mx-2" [ngbCollapse]="isCollapsed" id="navbarNavDropdown">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" (click)="resetHome()" routerLink="/">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="assets/docs/pledge.pdf" target="_blank">
                            Pledge Agreement Form (pdf)
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link"
                           href="https://givingutahedu.submittable.com/submit/69464/payroll-deduction-enrollment"
                           target="_blank">UofU Employee Payroll Deduction
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>
