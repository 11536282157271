<!--Link to open modal-->
<h2 class="form-label-underline">Do You Work for a Matching Company?</h2>
<div class="mb-4">
  Check here to see if your employer will
  <a (click)="openWindowCustomClass(content)" onclick="return false;" href="#">match your gift!</a>
</div>
<div class="mb-3 row" [formGroup]="form">
  <label for="matchingCompany" class="col-sm-3 col-form-label"><strong>Matching Company:</strong></label>
  <div class="col-sm-9 ">
    <input type="text" class="form-control" maxlength="200" id="matchingCompany" formControlName="matchingCompany"
           name="matchingCompany" placeholder="Matching Company">
    <app-field-errors [field]="form.controls.matchingCompany">
    </app-field-errors>
  </div>
</div>


<!--Modal-->
<ng-template #content let-d="dismiss()">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Matching Gifts</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="d">
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <p>With matching gifts you can sometimes double or even triple your gift!</p>
        <p>Many employers sponsor matching gift programs and will match charitable
          contributions made by their employees. To find out if your company has a
          matching gift policy, please enter your employer's name below.</p>
        <div class="ratio ratio-21x9">
          <iframe class="double-the-donation"
                  src="https://doublethedonation.com/api/iframe/OTdkZjZhMGEtMmFm">
            <p>Your browser does not support iframes.</p>
          </iframe>
        </div>
        <p class="pt-3">If your company matches you may be able to access the forms directly through the search. Follow
          the set procedure,
          and the University of Utah will be happy to assist. The impact of your gift may be doubled or possibly
          tripled! Some
          companies match gifts made by retirees and/or spouses.</p>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="d">Close</button>
  </div>
</ng-template>



