import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {NavigationService} from '../shared/navigation.service';

@Injectable({
    providedIn: 'root'
})
export class CustomerNotAllowedGuard implements CanActivate {

    constructor(private readonly navigationService: NavigationService) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.hasCustomerQueryParam(next)) {
            this.navigationService.navigateToSelfWithOutCustomer(next);
            return false;
        }
        return true;
    }

    hasCustomerQueryParam(next: ActivatedRouteSnapshot): boolean {
        const customer = next.queryParams['customer'];
        return !!customer;
    }
}
