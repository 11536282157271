<li *ngIf="affiliation"><span class="fw-bold">U of U Affiliation:</span>
  <ul>
    <li *ngIf="affiliation.friend"><span class="fw-bold">Friend</span></li>
    <li *ngIf="affiliation.student"><span class="fw-bold">Student:</span> {{affiliation.emplid}}</li>
    <li *ngIf="affiliation.facultyOrStaff"><span class="fw-bold">Faculty or Staff:</span> {{affiliation.emplid}}</li>
    <li *ngIf="affiliation.alum"><span class="fw-bold">Alumni:</span>
      <ul>
        <li><span class="fw-bold">Class Of:</span> {{affiliation.classOf}}</li>
        <li><span class="fw-bold">Degrees:</span> {{affiliation.degrees}}</li>
      </ul>
    </li>
  </ul>
</li>
