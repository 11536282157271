import {Component} from '@angular/core';
import {BaseGiftInformation} from '../base-gift-information';
import {GiftInformationNavService} from '../gift-information-nav.service';
import {GiftService} from '../gift.service';
import {GiftType} from '../gift-type';
import {Router} from '@angular/router';
import {Gift} from '../gift';
import {NotificationService} from '@uofu-uss/angular-util';

@Component({
  selector: 'app-your-gift',
  templateUrl: './your-gift.component.html',
  styleUrls: ['./your-gift.component.scss']
})

export class YourGiftComponent extends BaseGiftInformation {

  constructor(giftService: GiftService,
              giftInformationNavService: GiftInformationNavService,
              notificationService: NotificationService,
              router: Router) {
    super(giftService, giftInformationNavService, notificationService, router);
  }

  oneTimeGift() {
    this.next(this.updateGiftType(GiftType.ONE_TIME_GIFT));
  }

  recurringGift() {
    this.updateGiftType(GiftType.RECURING_GIFT);
  }

  // This function updates gift type in the session storage
  updateGiftType(value: GiftType): Gift {
    const gift = this.giftService.retrieveGift();
    gift.type = value;
    this.giftService.storeGift(gift);
    return gift;
  }

}
