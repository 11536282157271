import {Component, OnInit} from '@angular/core';
import {GiftService} from '../../gift-information/gift.service';
import {Gift} from '../../gift-information/gift';
import {UccpConfiguration, UccpService} from '../uccp.service';
import {Router} from '@angular/router';
import {OfficeContactMessage} from '../../shared/office-contact/office-contact-message';
import {NotificationService} from '@uofu-uss/angular-util';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-confirmation-main',
  templateUrl: './confirmation-main.component.html',
  styleUrls: ['./confirmation-main.component.scss']
})
export class ConfirmationMainComponent implements OnInit {

  gift: Gift;
  uccpConfiguration: UccpConfiguration;

  constructor(private readonly giftService: GiftService,
              private readonly uccpService: UccpService,
              private readonly notificationService: NotificationService,
              private readonly router: Router) {
  }

  ngOnInit() {
    if (this.giftService.hasGift()) {
      this.gift = this.giftService.retrieveGift();
    } else {
      // As a general rule I don't think this is possible. I assume it only happens when coming back from UCCP and we are
      // redirected back to the wrong base. For example, we start at localhost:4200 -> UCCP -> ugive.test.app.utah.edu
      // In other cases, it should be present. If not, I don't know that the servers HTTP session (via session cookie) will still be active.
      // As a result, this call would also fail.
      //
      // Even more silly is that fact that the ActiveGiftGuard, tied to this page, should prevent this component from loading without
      // a gift in session storage.
      //
      // Perhaps we should delete this code.
      this.giftService.retrieveLatestGift().subscribe(gift => {
        this.gift = gift;
        this.giftService.storeGift(this.gift);
      }, () => this.handleSessionLost());
    }

    this.uccpService.getUccpInfo()
      .pipe(first())
      .subscribe((config) => this.uccpConfiguration = config,
        () => this.notificationService.addWarning({
          title: 'An error occurred that will prevent you from making a gift',
          body: OfficeContactMessage.instance.contactIfErrorContinues
        }));
  }


  handleSessionLost() {
    this.notificationService.keepAlive(true);
    this.notificationService.addWarning({title: 'Your session has expired', body: ''});
    this.router.navigate(['']);
  }
}
