<div class="ie-stickyFooter">
  <div class="app-page-wrapper">
    <div class="flex-container" [ngClass]="{'bg-image': onHomePage}">
      <app-header *ngIf="!customTheme"></app-header>
      <app-huntsman-header *ngIf="useHuntsmanMentalHealthInstituteTheme"></app-huntsman-header>
      <main class="flex-content" [ngClass]="{'home-main-content-flex': onHomePage}"
            id="uu-skip-target" tabindex="-1" role="main">
        <div class="container-fluid">
          <app-global-alert></app-global-alert>
          <router-outlet (activate)="onActivate()"></router-outlet>
        </div>
      </main>
      <app-footer></app-footer>
    </div>
  </div>
</div>

