import {NgModule} from '@angular/core';
import {MatchDonationModalComponent} from './match-donation-modal/match-donation-modal.component';
import {ContactInformationComponent} from './contact-information.component';
import {JointGiftComponent} from './joint-gift/joint-gift.component';
import {GiftSummaryComponent} from './gift-summary/gift-summary.component';
import {AffiliationComponent} from './affiliation/affiliation.component';
import {ContactInfoAddressComponent} from './contact-info-address/contact-info-address.component';
import {PersonalInformationComponent} from './personal-information/personal-information.component';
import {BusinessInformationComponent} from './business-information/business-information.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {AccessibilityModule} from '@uofu-uss/angular-util';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    AccessibilityModule
  ],
  declarations: [
    PersonalInformationComponent,
    ContactInformationComponent,
    GiftSummaryComponent,
    JointGiftComponent,
    ContactInfoAddressComponent,
    AffiliationComponent,
    MatchDonationModalComponent,
    BusinessInformationComponent
  ],
  exports: [
    ContactInformationComponent
  ]
})
export class ContactInformationModule {
}
