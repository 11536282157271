import {Component, OnInit} from '@angular/core';
import {AlphabetService} from '../alphabet.service';
import {DesignationService} from '../designation.service';
import {Designation} from '../designation';
import {GiftInformationNavService} from '../../gift-information/gift-information-nav.service';
import {first} from 'rxjs/operators';
import {OfficeContactMessage} from '../../shared/office-contact/office-contact-message';
import {NotificationService} from '@uofu-uss/angular-util';

@Component({
  selector: 'app-designations-list',
  templateUrl: './designations-list.component.html',
  styleUrls: ['./designations-list.component.scss']
})
export class DesignationsListComponent implements OnInit {

  allDesignations: Designation[];

  error = false;

  constructor(private readonly alphabetService: AlphabetService,
              public readonly designationService: DesignationService,
              private readonly giftInformationNavService: GiftInformationNavService,
              private readonly notificationService: NotificationService) {
  }

  public fetchLetters() {
    return this.alphabetService.getLetters();
  }

  public fetchDesignations() {
    return this.designationService.getDesignations()
      .pipe(first())
      .subscribe(designations => this.allDesignations = designations,
        () => this.handleErrorLoadingDesignations());
  }

  private handleErrorLoadingDesignations() {
    this.notificationService.addWarning('Failed to pull designations. ' +
      OfficeContactMessage.instance.contactIfErrorContinues);
    this.error = true;
  }

  ngOnInit() {
    this.fetchDesignations();
    this.giftInformationNavService.reset();
  }

}
