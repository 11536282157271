<div *ngIf="!receipt" class="mb-4 d-none d-lg-block d-xl-block">
    <img *ngIf="!customTheme" class="img-fluid" src="assets/images/uofu_medallion.jpg"/>
    <img *ngIf="customTheme" class="img-fluid" src="assets/images/gratitude.png"/>
</div>
<div *ngIf="receipt" class="mb-4 text-center">
    <img class="img-fluid" src="assets/images/Heidi-Woodbury-smaller.jpg"/>
</div>
<p *ngIf="receipt">
    <em *ngIf="!customTheme">
        Thank you for your support of the University of Utah.
    </em>
    <em *ngIf="customTheme">
        Thank you for your support of the Huntsman Mental Health Institute.
    </em>
  <em>
    If you have any additional questions or comments about your donation, please contact the Development Office.
  </em>
    <br><br>
    <strong>Heidi Woodbury</strong><br>
    Vice President of Institutional Advancement<br>
</p>
<div class="d-none d-lg-block d-xl-block">
    <address><strong>{{officeContact.address.department}}</strong><br>
        {{officeContact.address.addressOne}}<br>
        {{officeContact.address.city}}, {{officeContact.address.state}} {{officeContact.address.zip}}<br>
        Phone: <a href="tel:{{officeContact.phone}}">{{officeContact.phone}}</a><br>
        Toll-Free: <a href="tel:{{officeContact.tollFree}}">{{officeContact.tollFree}}</a></address>
</div>
