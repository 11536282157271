import {NgModule} from '@angular/core';
import {NotifySomeoneComponent} from './notify-someone/notify-someone.component';
import {YourGiftComponent} from './your-gift/your-gift.component';
import {GiftInformationComponent} from './gift-information.component';
import {GiftAmountComponent} from './gift-amount/gift-amount.component';
import {GiftInformationNavService} from './gift-information-nav.service';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {ValidationModule} from '../shared/validation/validation.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AccessibilityModule} from '@uofu-uss/angular-util';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    ValidationModule,
    NgbModule,
    AccessibilityModule
  ],
  declarations: [
    GiftInformationComponent,
    YourGiftComponent,
    GiftAmountComponent,
    NotifySomeoneComponent,
  ],
  providers: [
    GiftInformationNavService
  ],
  exports: [
    GiftInformationComponent
  ]
})
export class GiftInformationModule {
}
