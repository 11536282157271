<h2 class="h3 card-title text-condensed mt-5 mb-3">Choose a Fund</h2>
<hr>

<div class="card-body text-center" *ngIf="designationsByNeed">
    <ul class="list-unstyled">
        <li *ngFor="let designation of designationsByNeed" class="d-grid gap-3">
            <button class="btn btn-primary" type="button"
                    routerLink="/designation/{{designation?.id}}"
                    routerLinkActive="active">
                {{designation?.name}}
            </button>
            <br>
        </li>
    </ul>
</div>

<div class="row justify-content-center m-2">
    <button id="back" class="btn btn-primary w-auto" (click)="goBack()">BACK</button>
</div>
