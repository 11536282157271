import {Component, OnInit} from '@angular/core';
import {Category} from '../category';
import {CategoryService} from '../../category/category.service';
import {first} from 'rxjs/operators';
import {HomeStateService} from '../home-state.service';
import {OfficeContactMessage} from '../../shared/office-contact/office-contact-message';
import {NotificationService} from '@uofu-uss/angular-util';

@Component({
  selector: 'app-by-category',
  templateUrl: './by-category.component.html',
  styleUrls: ['./by-category.component.scss']
})
export class ByCategoryComponent implements OnInit {

  rootCategories: Array<Category> = [];

  constructor(private categoryService: CategoryService,
              private readonly homeStateService: HomeStateService,
              private readonly notificationService: NotificationService) {
  }

  ngOnInit() {
    this.categoryService.fetchCategories()
      .pipe(first())
      .subscribe(this.handleLoadingCategories, this.handleLoadError);
  }

  private handleLoadingCategories = (categories: Category[]): void => {
    this.rootCategories = categories;
  }

  private handleLoadError = (): void => {
    this.notificationService.addWarning('An error prevented the page from working. ' +
      OfficeContactMessage.instance.contactIfErrorContinues);
    this.homeStateService.resetState();
  }
}
