import {Component, OnInit} from '@angular/core';
import {GiftService} from '../../gift-information/gift.service';
import {Gift} from '../../gift-information/gift';
import {ActivatedRoute, Router} from '@angular/router';
import {OfficeContactMessage} from '../../shared/office-contact/office-contact-message';
import {NotificationService} from '@uofu-uss/angular-util';

@Component({
  selector: 'app-receipt-main',
  templateUrl: './receipt-main.component.html',
  styleUrls: ['./receipt-main.component.scss']
})
export class ReceiptMainComponent implements OnInit {

  gift: Gift;

  constructor(private readonly giftService: GiftService,
              private readonly route: ActivatedRoute,
              private readonly router: Router,
              private readonly notificationService: NotificationService) {
  }

  ngOnInit() {
    const id: number = +this.route.snapshot.queryParams.ID;

    if (!id) {
      this.displayError();
    } else {
      this.giftService.retrieveGiftWithId(id).subscribe(gift => this.gift = gift, () => {
        this.displayError();
      });
    }

    this.giftService.clearGift();
  }

  displayError() {
    this.notificationService.keepAlive(true);
    this.notificationService.addWarning({
      title: 'Your session has expired',
      body: `A receipt has been sent to the email address you provided. ${OfficeContactMessage.instance.contactIfQuestions}`
    });
    this.router.navigate(['']);

  }

}
