import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {BaseContactInfoComponent} from '../base-contact-info/base-contact-info.component';

@Component({
  selector: 'app-business-information',
  templateUrl: './business-information.component.html',
  styleUrls: ['./business-information.component.scss'],
  inputs: ['contact'],
})
export class BusinessInformationComponent extends BaseContactInfoComponent implements OnInit {

  constructor(private readonly fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.form = this.fb.group({
      businessName: [this.contact.businessName, [Validators.required, Validators.maxLength(200)]]
    });
  }

  save() {
    this.contact.businessName = this.form.controls.businessName.value;
  }

}
