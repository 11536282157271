import {NgModule} from '@angular/core';
import {HomeComponent} from './home.component';
import {ByNeedComponent} from './by-need/by-need.component';
import {ByCategoryComponent} from './by-category/by-category.component';
import {RouterModule} from '@angular/router';
import {FundSelectComponent} from './fund-select/fund-select.component';
import {SharedModule} from '../shared/shared.module';
import {ByCategorySelectionComponent} from './by-category-selection/by-category-selection.component';
import {FormsModule} from '@angular/forms';
import {HomeStateService} from './home-state.service';
import { ByCovidComponent } from './by-covid/by-covid.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    FormsModule
  ],
  declarations: [
    HomeComponent,
    FundSelectComponent,
    ByCategoryComponent,
    ByCategorySelectionComponent,
    ByNeedComponent,
    ByCovidComponent
  ],
  exports: [
    HomeComponent
  ],
  providers: [
    HomeStateService
  ],
})
export class HomeModule {
}
