import {Component, OnInit, ViewChild} from '@angular/core';
import {GiftService} from '../gift-information/gift.service';
import {Router} from '@angular/router';
import {FormValidationService} from '../shared/validation/form-validation.service';
import {Gift} from '../gift-information/gift';
import {PersonalInformationComponent} from './personal-information/personal-information.component';
import {JointGiftComponent} from './joint-gift/joint-gift.component';
import {BusinessInformationComponent} from './business-information/business-information.component';
import {ContactInfoAddressComponent} from './contact-info-address/contact-info-address.component';
import {AffiliationComponent} from './affiliation/affiliation.component';
import {MatchDonationModalComponent} from './match-donation-modal/match-donation-modal.component';
import {BaseContactInfoComponent} from './base-contact-info/base-contact-info.component';
import {CustomerService} from '../customer/customer.service';
import {NotificationService} from '@uofu-uss/angular-util';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.scss']
})
export class ContactInformationComponent implements OnInit {

  gift: Gift;

  isDonationFromBusiness: boolean;

  @ViewChild(PersonalInformationComponent)
  protected personalInformationComponent: PersonalInformationComponent;

  @ViewChild(BusinessInformationComponent)
  protected businessInformationComponent: BusinessInformationComponent;

  @ViewChild(JointGiftComponent)
  protected jointGiftComponent: JointGiftComponent;

  @ViewChild(ContactInfoAddressComponent)
  protected contactInfoAddressComponent: ContactInfoAddressComponent;

  @ViewChild(AffiliationComponent)
  protected affiliationComponent: AffiliationComponent;

  @ViewChild(MatchDonationModalComponent)
  protected matchDonationModalComponent: MatchDonationModalComponent;

  children: Array<BaseContactInfoComponent> = [];

  constructor(private readonly giftService: GiftService,
              private readonly formValidationService: FormValidationService,
              protected notificationService: NotificationService,
              private readonly router: Router,
              private readonly customerService: CustomerService) { }

  get customTheme(): boolean {
    return this.customerService.requiresCustomization();
  }

  pushChildren() {
    this.children.push(this.businessInformationComponent);
    this.children.push(this.personalInformationComponent);
    this.children.push(this.contactInfoAddressComponent);
    this.children.push(this.affiliationComponent);
    this.children.push(this.jointGiftComponent);
    this.children.push(this.matchDonationModalComponent);
    this.children = this.children.filter(child => {
      return !!child;
    });
  }

  ngOnInit() {
    this.gift = this.giftService.retrieveGift();
    this.isDonationFromBusiness = !this.gift.contact.donationFromBusiness ? false : this.gift.contact.donationFromBusiness;
  }

  saveAll() {
    this.children.forEach(child => {
      child.save();
    });
    this.giftService.storeGift(this.gift);
  }

  showAllValidationsErrors() {
    const formArray = [];
    this.children.forEach(child => {
      formArray.push(child.getForm());
    });
    this.formValidationService.showValidationErrors(formArray);
  }

  allFormsAreValid() {
    let isValid = true;
    this.children.forEach(child => {
      if (!child.isValid()) {
        isValid = false;
      }
    });
    return isValid;
  }

  clearPersonalInfo() {
    this.gift.contact.email = '';
    this.gift.contact.matchingCompany = '';
    this.gift.contact.spouseName = '';
    this.gift.contact.phone = '';
    this.gift.contact.title = '';
    this.gift.contact.name.lastName = '';
    this.gift.contact.name.firstName = '';
    this.gift.contact.name.middleName = '';
  }

  clearBusinessInfo() {
    this.gift.contact.businessName = '';
  }

  clearBadInfo() {
    if (this.isDonationFromBusiness) {
      this.clearPersonalInfo();
    } else {
      this.clearBusinessInfo();
    }
  }

  updateAndProceed() {
    this.pushChildren();
    // update the donation value early so that if they fail validation then they don't lose data.
    this.gift.contact.donationFromBusiness = this.isDonationFromBusiness;
    this.giftService.storeGift(this.gift);
    // then run validation.
    if (this.allFormsAreValid()) {
      this.notificationService.clear();
      this.clearBadInfo();
      this.saveAll();
      this.gotoConfirmation();
    } else {
      this.showAllValidationsErrors();
    }
  }

  gotoConfirmation() {
    this.router.navigate(['/confirmation'], {queryParamsHandling: 'preserve'});
  }
}
