import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {SkipLinkComponent} from './skip-link/skip-link.component';
import {GlobalAlertComponent} from './global-alert/global-alert.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ValidationModule} from './validation/validation.module';
import {GeoService} from './geo/geo.service';
import {RouterModule} from '@angular/router';
import {LoaderComponent} from './loader/loader.component';
import {HuntsmanHeaderComponent} from './huntsman/huntsman-header.component';
import {NotificationModule} from '@uofu-uss/angular-util';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NotificationModule,
        ValidationModule,
        RouterModule
    ],
    declarations: [
        HeaderComponent,
        HuntsmanHeaderComponent,
        FooterComponent,
        SkipLinkComponent,
        GlobalAlertComponent,
        LoaderComponent
    ],
    providers: [
        GeoService,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NotificationModule,
        ValidationModule,
        HeaderComponent,
        HuntsmanHeaderComponent,
        FooterComponent,
        SkipLinkComponent,
        GlobalAlertComponent,
        LoaderComponent
    ]
})
export class SharedModule {
}
