import {NgModule} from '@angular/core';
import {MyInfoComponent} from './my-info/my-info.component';
import {GiftSummaryComponent} from './gift-summary/gift-summary.component';
import {BusinessDonationComponent} from './business-donation/business-donation.component';
import {MatchingCompanyComponent} from './matching-company/matching-company.component';
import {ConfirmationMainComponent} from './confirmation-main/confirmation-main.component';
import {LeftSummaryComponent} from './left-summary/left-summary.component';
import {JointGiftComponent} from './joint-gift/joint-gift.component';
import {SectionHeaderComponent} from './section-header/section-header.component';
import {RouterModule} from '@angular/router';
import {AddressBuilderService} from './address-builder.service';
import {UofuAffiliationComponent} from './uofu-affiliation/uofu-affiliation.component';
import {ReceiptMainComponent} from './receipt-main/receipt-main.component';
import {GenericMainComponent} from './generic-main/generic-main.component';
import {SharedModule} from '../shared/shared.module';
import {MakePaymentComponent} from './make-payment/make-payment.component';
import {UccpService} from './uccp.service';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
  ],
  declarations: [
    MyInfoComponent,
    GiftSummaryComponent,
    BusinessDonationComponent,
    MatchingCompanyComponent,
    ConfirmationMainComponent,
    LeftSummaryComponent,
    JointGiftComponent,
    SectionHeaderComponent,
    UofuAffiliationComponent,
    ReceiptMainComponent,
    GenericMainComponent,
    MakePaymentComponent
  ],
  providers: [
    AddressBuilderService,
    UccpService
  ],
  exports: [
    ConfirmationMainComponent,
    ReceiptMainComponent
  ]
})
export class ConfirmationReceiptModule {
}
