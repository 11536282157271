import {Component, OnInit} from '@angular/core';
import {HomeStateService} from '../home-state.service';

@Component({
  selector: 'app-by-covid',
  templateUrl: './by-covid.component.html',
  styleUrls: ['./by-covid.component.scss']
})
export class ByCovidComponent {

  constructor(private readonly homeStateService: HomeStateService) {
  }

  goBack() {
    this.homeStateService.resetState();
  }

}
