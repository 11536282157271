import {FormGroup} from '@angular/forms';
import {Contact} from '../contact';
import {Component, Input} from '@angular/core';

@Component({
  template: '<div></div>',
})
export abstract class BaseContactInfoComponent {

  @Input()
  public contact: Contact;

  public form: FormGroup;

  public getForm(): FormGroup {
    return this.form;
  }

  public isValid(): boolean {
    return this.form.valid;
  }

  public abstract save();

}

