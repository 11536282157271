import {Component, Input, OnInit} from '@angular/core';
import {Gift} from '../../gift-information/gift';

@Component({
  selector: 'app-matching-company',
  templateUrl: './matching-company.component.html',
  styleUrls: ['./matching-company.component.scss']
})
export class MatchingCompanyComponent implements OnInit {

  @Input()
  gift: Gift;
  matchingCompanyName = '';

  constructor() {
  }

  ngOnInit() {
    this.matchingCompanyName = this.gift.contact.matchingCompany;
  }

}
