import {Component} from '@angular/core';
import {AlphabetService} from '../alphabet.service';

@Component({
  selector: 'app-alphabet-nav-bar',
  templateUrl: './alphabet-nav-bar.component.html',
  styleUrls: ['./alphabet-nav-bar.component.scss']
})
export class AlphabetNavBarComponent {

  constructor(public alphabetService: AlphabetService) {
  }

}
