import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, Validators} from '@angular/forms';
import {BaseContactInfoComponent} from '../base-contact-info/base-contact-info.component';

@Component({
  selector: 'app-match-donation-modal',
  templateUrl: './match-donation-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./match-donation-modal.component.scss'],
  inputs: ['contact'],
})
export class MatchDonationModalComponent extends BaseContactInfoComponent  implements OnInit {

  constructor(private modalService: NgbModal, private readonly fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.form = this.fb.group({
      matchingCompany: [this.contact.matchingCompany, [Validators.maxLength(200)]]
    });
  }

  openWindowCustomClass(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass: 'custom'});
  }

  save() {
    this.contact.matchingCompany = this.form.controls.matchingCompany.value;
  }

}
