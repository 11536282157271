import {Component, Input, OnInit} from '@angular/core';
import {DesignationService} from '../../designation/designation.service';
import {AddressBuilderService} from '../address-builder.service';
import {Gift} from '../../gift-information/gift';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-gift-summary',
  templateUrl: './gift-summary.component.html',
  styleUrls: ['./gift-summary.component.scss']
})
export class GiftSummaryComponent implements OnInit {

  @Input() designationId: number;
  @Input() receipt = false;
  @Input() gift: Gift;
  designationName: string;
  specialInstructions: string;
  giftType: string;
  amount: number;
  dedicationName: string;
  dedicationType: string;
  isDedication: boolean;
  notifyInfo: string;
  shouldNotify: boolean;


  constructor(private designationService: DesignationService,
              private addressBuilder: AddressBuilderService) {
  }

  ngOnInit() {
    this.fetchDesignation();
    this.giftType = this.titleCase(this.gift.type).replace('_', ' ');
    this.amount = this.gift.amount;
    this.specialInstructions = this.gift.specialInstructions;
    this.isDedication = this.gift.dedication && this.gift.dedication.dedicate;
    this.dedicationType = this.isDedication ? this.titleCase(this.gift.dedication.type) : '';
    this.dedicationName = this.isDedication ? this.gift.dedication.name : '';
    this.shouldNotify = this.gift.notification && this.gift.notification.notify;
    if (this.shouldNotify) {
      this.notifyInfo = this.gift.notification ? this.addressBuilder.buildNotificationAddress(this.gift) : '';
    }
  }

  titleCase(str: string = '') {
    return str ? this.startCase((str || '').toLowerCase()) : str;
  }

  fetchDesignation() {
    this.designationService.getDesignation(this.designationId)
      .pipe(first())
      .subscribe((designation) => {
        this.designationName = designation.name;
      });
  }

  startCase(str: string): string {
    // Replace non-word characters and underscores with space, and split camel case
    const words = str
        .replace(/[\W_]+/g, ' ')
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .split(' ');

    // Capitalize the first character of each word
    const result = words.map((word) =>
        word.charAt(0).toUpperCase() + word.slice(1)
    );

    return result.join(' ').trim();
  }


}
