import {Injectable} from '@angular/core';
import {AbstractControl, AbstractControlDirective} from '@angular/forms';
import {NotificationService, ValidationService} from '@uofu-uss/angular-util';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {

  constructor(private readonly validationService: ValidationService,
              private readonly notificationService: NotificationService) {
  }

  showValidationErrors(forms: Array<AbstractControl | AbstractControlDirective> = [], msgs?: { [code: string]: string; }) {
    this.notificationService.clear();
    // space is used as a workaround to allow multiple notifications to be displayed
    // this works because multiple can be displayed if they are not the same.
    let space = '';
    forms.forEach((form) => {
      this.validationService.validateForm(form, msgs).forEach((fieldErr) => {
        this.notificationService.addWarning({
          title: `Unable to validate field ${space}`,
          body: fieldErr.message,
          focus: fieldErr.field,
        });
        space += ' ';
      });
    });
  }
}
