export class OfficeAddress {

  private static _instance: OfficeAddress;

  private _department = 'Development Office';
  private _addressOne = '332 South 1400 East, Suite 150';
  private _city = 'Salt Lake City';
  private _state = 'UT';
  private _zip = '84112-0300';

  get department(): string {
    return this._department;
  }

  get addressOne(): string {
    return this._addressOne;
  }

  get city(): string {
    return this._city;
  }

  get state(): string {
    return this._state;
  }

  get zip(): string {
    return this._zip;
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

}
