<br>
<div class="row">
  <div class="fund-info col-12 col-lg-8 order-lg-1">
    <ngb-carousel [showNavigationArrows]="false" [showNavigationIndicators]="false">
      <ng-template ngbSlide>
        <img [src]="imgUrl" (error)="img.src=errorSlide" class="img-fluid" alt="Random slide" #img>
      </ng-template>
    </ngb-carousel>
    <h1 class="text-condensed mt-4 mb-3">{{designation?.name}}</h1>
    <p *ngIf="designation?.description; else noDesc" class="fund-description mb-5">{{designation?.description}}</p>
    <ng-template #noDesc>
      <p class="fund-description mb-5">You’re making transformative learning experiences possible for our students, advancing research for our faculty,
        and providing opportunities to our citizens through our medical, athletic and cultural venues. Because of you and other generous
      donors, the University has never been stronger nor our future brighter.</p>
    </ng-template>
    <div class="d-none d-lg-block d-xl-block">
      <h2 class="pb-2 mt-5 h5 text-underline">Other Gift Options</h2>
      <h3 class="pt-2 h6">Scheduled Pledge Payments</h3>
      <p class="small-other-gift">A pledge is an agreement to pay a set amount over a period of time with scheduled payments or transfers from a bank account or credit card. Please mail a <a target="_blank" href="assets/docs/pledge.pdf">completed PDF</a> with your credit card number or a voided check.</p>
      <h3 class="pt-2 h6">Pledge By Phone</h3>
      <p class="small-other-gift">To make a pledge by phone or give in other ways (planned gifts, securities) call <a href="tel:+1-800-716-0377">1-800-716-0377</a>.</p>
      <div *ngIf="!customTheme">
        <h3 class="pt-2 h6">University of Utah Employee Payroll Deduction</h3>
        <p class="pb-3 small-other-gift">For employees of the University of Utah. To set up a one-time or recurring gift to be directed from your paycheck please complete this <a target="_blank" href="https://givingutahedu.submittable.com/submit/69464/payroll-deduction-enrollment">online form</a>.</p>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-4" [ngSwitch]="giftInformationNavService.currentView">
    <app-your-gift *ngSwitchCase="'YOUR_GIFT'">
    </app-your-gift>
    <app-gift-amount *ngSwitchCase="'GIFT_AMOUNT'">
    </app-gift-amount>
    <app-notify-someone *ngSwitchCase="'NOTIFY_SOMEONE'">
    </app-notify-someone>
  </div>
</div>
