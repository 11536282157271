<br/>
<div class="row">
    <div class="col-12 col-lg-4 pb-3">
        <app-gift-summary></app-gift-summary>
    </div>

    <div class="fund-info col-12 col-lg-8">

        <div class="pb-5">
            <h1>Contact Information</h1>
        </div>

        <form>
            <div class="row justify-content-center">
                <div class="card card-business col-11 col-lg-6">
                    <div class="form-check p-1 m-3">
                        <input class="form-check-input"
                               type="checkbox"
                               [(ngModel)]="isDonationFromBusiness"
                               id="isDonationFromBusiness"
                               name="isDonationFromBusiness">
                        <label class="form-check-label ps-3" for="isDonationFromBusiness">
                            <strong>This is a Business or Organization Credit Card</strong>
                        </label>
                    </div>
                </div>
            </div>

            <app-business-information class="p-2" *ngIf="isDonationFromBusiness"
                                      [contact]="gift.contact"></app-business-information>
            <app-personal-information class="p-2" [isDonationFromBusiness]="isDonationFromBusiness"
                                      [contact]="gift.contact"></app-personal-information>
            <app-contact-info-address class="p-2" [isDonationFromBusiness]="isDonationFromBusiness"
                                      [contact]="gift.contact"></app-contact-info-address>
            <app-uofu *ngIf="!customTheme" class="p-2" [contact]="gift.contact"></app-uofu>
            <app-joint-gift class="p-2" *ngIf="!isDonationFromBusiness" [contact]="gift.contact"></app-joint-gift>
            <app-match-donation-modal class="p-2" *ngIf="!isDonationFromBusiness"
                                      [contact]="gift.contact"></app-match-donation-modal>

            <div class="p-2 form-group row justify-content-center">
                <div class="col-auto">
                    <button id="backButton" class="btn btn-primary m-2" type="button"
                            routerLink="{{gift.designationId ? ('/designation/' + gift.designationId) : ''}}"
                            queryParamsHandling="preserve">
                        &lt; BACK
                    </button>
                    <button id="continueButton" class="btn btn-primary m-2" type="submit" (click)="updateAndProceed()">
                        CONTINUE &gt;
                    </button>
                </div>
            </div>
        </form>

    </div>
</div>
