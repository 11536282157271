import {Component, HostListener, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Router} from '@angular/router';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss'],
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({opacity: 1})),
      state('hidden', style({opacity: 0})),
      transition('shown => hidden', animate('600ms')),
      transition('hidden => shown', animate('300ms')),
    ])
  ]
})
export class ScrollToTopComponent implements OnInit {

  public visibilityState = 'hidden';
  public currentRoute: string;

  constructor(private readonly router: Router) {

  }

  ngOnInit() {
    this.currentRoute = this.router.url.replace('/', '');
  }

  @HostListener('window:scroll', [])
  onScroll() {
    const yScrollAmount = window.scrollY || window.pageYOffset;
    this.visibilityState = (yScrollAmount > 100) ? 'shown' : 'hidden';
  }

}

