export class OfficeContactMessage {

  private static _instance: OfficeContactMessage;

  private _contactIfErrorContinues = 'Please contact the Development Office if you continue to encounter this problem.';
  private _contactIfQuestions = 'Please contact the Development Office if you have any questions.';

  get contactIfErrorContinues() {
    return this._contactIfErrorContinues;
  }

  get contactIfQuestions() {
    return this._contactIfQuestions;
  }

  private constructor() {
  }

  public static get instance() {
    return this._instance || (this._instance = new this());
  }
}
