import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {BaseContactInfoComponent} from '../base-contact-info/base-contact-info.component';

@Component({
  selector: 'app-joint-gift',
  templateUrl: './joint-gift.component.html',
  styleUrls: ['./joint-gift.component.scss'],
  inputs: ['contact'],
})
export class JointGiftComponent extends BaseContactInfoComponent  implements OnInit {

  constructor(private readonly fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.form = this.fb.group({
      spouseName: [this.contact.spouseName, [Validators.maxLength(100)]]
    });
  }

  save() {
    this.contact.spouseName = this.form.controls.spouseName.value;
  }

}
