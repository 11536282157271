import {
  Directive,
  DoCheck,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Directive({
  selector: '[appIfFieldErrors]'
})
export class IfFieldErrorsDirective implements OnInit, DoCheck {
  private hasView = false;

  @Input() appIfFieldErrors: AbstractControl;

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {
  }

  ngOnInit(): void {
    this.updateComponentRendering();
  }


  ngDoCheck(): void {
    this.updateComponentRendering();
  }

  updateComponentRendering(): boolean {
    if (!this.appIfFieldErrors.valid  && this.showErrors(this.appIfFieldErrors)) {
      if (!this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      }
    } else {
      if (this.hasView) {
        this.viewContainer.clear();
        this.hasView = false;
      }
    }
    return this.hasView;
  }

  showErrors(given: AbstractControl) {
    return given && given.errors && given.invalid && (given.dirty || given.touched);
  }

}
