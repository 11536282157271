import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Contact} from '../../contact-information/contact';
import {GiftService} from '../../gift-information/gift.service';
import {Gift} from '../../gift-information/gift';
import {first} from 'rxjs/operators';
import {MakePaymentComponent} from '../make-payment/make-payment.component';
import {UccpConfiguration} from '../uccp.service';
import {CustomerService} from '../../customer/customer.service';
import {NotificationService} from '@uofu-uss/angular-util';

@Component({
  selector: 'app-generic-main',
  templateUrl: './generic-main.component.html',
  styleUrls: ['./generic-main.component.scss']
})
export class GenericMainComponent implements OnInit {

  @Input()
  title = '';
  @Input()
  message = '';
  @Input()
  receipt = false;
  @Input()
  gift: Gift;
  @Input()
  uccpConfiguration: UccpConfiguration;

  @ViewChild(MakePaymentComponent)
  makePaymentComponent: MakePaymentComponent;

  designationId: number;
  contact: Contact;

  constructor(private readonly giftService: GiftService,
              private readonly customerService: CustomerService,
              private readonly notificationService: NotificationService) {
  }

  ngOnInit() {
    this.designationId = +this.gift.designationId;
    this.contact = this.gift.contact;
  }

  confirmGift(): void {
    this.gift.customer = this.customerService.requiresCustomization() ? this.customerService.getCustomer() : null;
    this.giftService.persistGift(this.gift)
        .pipe(first())
        .subscribe((savedGift: Gift) => {
          this.giftService.storeGift(savedGift);
          this.makePaymentComponent.submit(savedGift);
        }, () => {
          this.notificationService.addWarning('Unable to confirm your gift.');
        });
  }

}
