import {Component, Input, OnInit} from '@angular/core';
import {OfficeContact} from '../office-contact/office-contact';
import {CustomerService} from '../../customer/customer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() officeContact: OfficeContact;

  constructor(private readonly customerService: CustomerService) {
  }

  ngOnInit() {
    this.officeContact = OfficeContact.Instance;
  }

  get customTheme(): boolean {
    return this.customerService.requiresCustomization();
  }

}
