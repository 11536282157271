import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {Designation} from '../designation/designation';
import {DesignationService} from '../designation/designation.service';
import {catchError, map, tap} from 'rxjs/operators';
import {GiftService} from '../gift-information/gift.service';
import {NavigationService} from '../shared/navigation.service';

@Injectable({
    providedIn: 'root'
})
export class ValidCustomerGuard implements CanActivate {

    constructor(private readonly giftService: GiftService,
                private readonly designationService: DesignationService,
                private readonly navigationService: NavigationService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (!this.hasCustomerQueryParam(next)) {
            return this.handleNoCustomerQueryParamResolution(next);
        }

        this.clearCustomerFromGift();
        const designationId = this.navigationService.fetchDesignationIdFromUrl(next) || this.fetchDesignationIdFromActiveGift();
        return this.designationService.getDesignation(designationId).pipe(
            map(designation => this.hasValidCustomerQueryParam(designation, next)),
            catchError(() => of(false)),
            tap(valid => {
                if (!valid) {
                    this.navigationService.navigateToSelfWithOutCustomer(next);
                }
            })
        );
    }

    fetchDesignationIdFromActiveGift(): number {
        const gift = this.loadGiftFromSessionStorage();
        return gift && gift.designationId;
    }

    hasValidCustomerQueryParam(designation: Designation, next: ActivatedRouteSnapshot): boolean {
        const customer = next.queryParams['customer'];
        const allowedCustomers = designation.allowedCustomers;
        return allowedCustomers && !!allowedCustomers.find((v) => v === customer);
    }

    hasCustomerQueryParam(next: ActivatedRouteSnapshot): boolean {
        return !!next.queryParams['customer'];
    }

    private handleNoCustomerQueryParamResolution(next: ActivatedRouteSnapshot): boolean {
        const gift = this.loadGiftFromSessionStorage();
        const hasCustomer = gift && !!gift.customer;
        if (hasCustomer) {
            this.navigationService.navigateToSelfWithCustomer(gift, next);
            this.clearCustomerFromGift(gift);
            return false;
        }
        return true;
    }

    // after navigation remove the customer from the gift to prevent a possible bad cycle.
    private clearCustomerFromGift(gift = this.loadGiftFromSessionStorage()) {
        const hasCustomer = gift && !!gift.customer;
        if (hasCustomer) {
            gift.customer = null;
            this.giftService.storeGift(gift);
        }
    }

    private loadGiftFromSessionStorage() {
        return this.giftService.hasGift() && this.giftService.retrieveGift();
    }
}
