import {NgModule} from '@angular/core';
import {AlphabetNavBarComponent} from './alphabet-nav-bar/alphabet-nav-bar.component';
import {DesignationsListComponent} from './designation-list/designations-list.component';
import {DesignationGroupsComponent} from './designation-group/designation-groups.component';
import {AlphabetService} from './alphabet.service';
import {DesignationService} from './designation.service';
import {RouterModule} from '@angular/router';
import {ScrollToTopComponent} from './scroll-to-top/scroll-to-top.component';
import {SharedModule} from '../shared/shared.module';
import {DefaultDesignationImageResolverService, DesignationImageResolverService} from './designation-image-resolver.service';

@NgModule({
  imports: [
    SharedModule,
    RouterModule
  ],
  declarations: [
    AlphabetNavBarComponent,
    ScrollToTopComponent,
    DesignationGroupsComponent,
    DesignationsListComponent
  ],
  providers: [
    AlphabetService,
    DesignationService,
    DefaultDesignationImageResolverService,
    DesignationImageResolverService
  ],
  exports: [
    DesignationsListComponent
  ]
})
export class DesignationModule {
}
