import {Injectable} from '@angular/core';
import {Gift} from '../gift-information/gift';
import {ActivatedRouteSnapshot, Params, Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    constructor(private readonly router: Router) {
    }

    navigateToHome() {
        this.router.navigate(['./']);
    }

    navigateToSelfWithCustomer(gift: Gift, next: ActivatedRouteSnapshot) {
        const path = next.routeConfig.path.replace(':id', this.fetchDesignationIdFromUrl(next) + '');
        this.router.navigate([path], {queryParams: this.generateQueryParams(next, gift.customer)});
    }

    navigateToSelfWithOutCustomer(next: ActivatedRouteSnapshot) {
        const path = next.routeConfig.path.replace(':id', this.fetchDesignationIdFromUrl(next) + '');
        this.router.navigate([path], {queryParams: this.generateQueryParams(next)});
    }

    fetchDesignationIdFromUrl(next: ActivatedRouteSnapshot): number {
        const id = next.params['id'];
        return id && +id;
    }

    // TODO Should be able to use navigationService with https://angular.io/api/router/NavigationExtras#preserveQueryParams
    private generateQueryParams(next: ActivatedRouteSnapshot, customer: string = null): Params {
        const params = {};
        this.addQueryParam(params, 'ID', next);
        this.addQueryParam(params, 'transaction_id', next);
        if (customer) {
            params['customer'] = customer;
        }
        return params;
    }

    private addQueryParam(map: Params, key: string, next: ActivatedRouteSnapshot): Params {
        if (next.queryParams[key]) {
            map[key] = next.queryParams[key];
        }
        return map;
    }

}
