import {Component, DestroyRef, Input, OnInit} from '@angular/core';
import {Contact} from '../contact';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Country} from '../../shared/geo/country';
import {Province} from '../../shared/geo/province';
import {GeoService} from '../../shared/geo/geo.service';
import {BaseContactInfoComponent} from '../base-contact-info/base-contact-info.component';
import {CountrySupport} from '../../shared/geo/country-support';
import {applyMixins} from '../../shared/mix-it-up';
import {NotificationService} from '@uofu-uss/angular-util';

@Component({
    selector: 'app-contact-info-address',
    templateUrl: './contact-info-address.component.html',
    styleUrls: ['./contact-info-address.component.scss'],
})
export class ContactInfoAddressComponent extends BaseContactInfoComponent implements OnInit, CountrySupport {
    @Input() isDonationFromBusiness: boolean;
    @Input() contact: Contact;

    // properties from CountrySupport mixin
    countries: Country[];
    provinces: Province[];
    loadCountries: (geoService: GeoService, notificationService: NotificationService, form: FormGroup) => void;
    initChangeSupport: (geoService: GeoService, notificationService: NotificationService, form: FormGroup, destroyRef: DestroyRef) => void;

    constructor(private readonly fb: FormBuilder,
                private readonly geoService: GeoService,
                private readonly notificationService: NotificationService,
                private readonly destroyRef: DestroyRef) {
        super();
    }

    ngOnInit() {
        this.form = this.fb.group({
            address1: [this.contact.address.address1, [Validators.required, Validators.maxLength(50)]],
            address2: [this.contact.address.address2, [Validators.maxLength(50)]],
            city: [this.contact.address.city, [Validators.required, Validators.maxLength(50)]],
            country: [this.contact.address.country, [Validators.required]],
            province: [this.contact.address.state],
            zipCode: [this.contact.address.zipCode, [Validators.required, Validators.maxLength(15)]],
        });
        this.loadCountries(this.geoService, this.notificationService, this.form);
        this.initChangeSupport(this.geoService, this.notificationService, this.form, this.destroyRef);
    }

    save() {
        this.contact.address.address1 = this.form.controls.address1.value;
        this.contact.address.address2 = this.form.controls.address2.value;
        this.contact.address.city = this.form.controls.city.value;
        this.contact.address.state = this.form.controls.province.value;
        this.contact.address.country = this.form.controls.country.value;
        this.contact.address.zipCode = this.form.controls.zipCode.value;
    }

}

applyMixins(ContactInfoAddressComponent, [CountrySupport]);
