import {Component, Input, TemplateRef} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Component({
  selector: 'app-field-errors',
  templateUrl: './field-errors.component.html',
  styleUrls: ['./field-errors.component.scss']
})
export class FieldErrorsComponent {

  @Input() field: AbstractControl;
  @Input() dateTemplate: TemplateRef<any>;
  @Input() emailTemplate: TemplateRef<any>;
  @Input() maxTemplate: TemplateRef<any>;
  @Input() maxLengthTemplate: TemplateRef<any>;
  @Input() minTemplate: TemplateRef<any>;
  @Input() minLengthTemplate: TemplateRef<any>;
  @Input() numberTemplate: TemplateRef<any>;
  @Input() parseTemplate: TemplateRef<any>;
  @Input() patternTemplate: TemplateRef<any>;
  @Input() precisionTemplate: TemplateRef<any>;
  @Input() requiredTemplate: TemplateRef<any>;
  @Input() urlTemplate: TemplateRef<any>;
  @Input() modulusTemplate: TemplateRef<any>;

  constructor() { }
}
