<div class="d-flex justify-content-between">
  <div>
    <h2 class="text-black text-condensed pb-1 ps-1 p-0 mb-0" id="{{designationLetter}}">{{designationLetter}}</h2>
  </div>
  <div *ngIf="notA()">
    <a href="designations-list#">
      <span class="pe-2">Top</span><i class="fa fa-angle-up fa-2x" style="vertical-align: middle;"></i>
    </a>
  </div>
</div>

<div class="card" *ngIf="designationList.length">
  <div class="card-body row">
    <ul class="col-lg-4 list-unstyled" *ngFor="let list of designationListSplit">
      <li *ngFor="let designation of list">
        <a routerLink="/designation/{{designation.id}}" routerLinkActive="active">
          {{designation.name}}
        </a>
        <hr>
      </li>
    </ul>
  </div>
</div>
