import { Injectable } from '@angular/core';
import {Gift} from '../gift-information/gift';
import {Contact} from '../contact-information/contact';

@Injectable({
  providedIn: 'root'
})
export class AddressBuilderService {

  constructor() { }

  buildNotificationAddress(gift: Gift) {
    let address = '';
    address += gift.notification.name ? gift.notification.name + ', ' : '';
    address += gift.notification.address.address1 ? gift.notification.address.address1 + ', ' : '';
    address += gift.notification.address.address2 ? gift.notification.address.address2 + ', ' : '';
    address += gift.notification.address.city ? gift.notification.address.city + ', ' : '';
    address += gift.notification.address.state ? gift.notification.address.state + ', ' : '';
    address += gift.notification.address.zipCode ? gift.notification.address.zipCode + ', ' : '';
    address += gift.notification.address.country ? gift.notification.address.country + ', ' : '';
    address = address.substr(0, address.length - 2);
    return address;
  }
  buildContactAddress(contact: Contact) {
    let address = '';
    address += contact.address.address1 ? contact.address.address1 + ', ' : '';
    address += contact.address.address2 ? contact.address.address2 + ', ' : '';
    address += contact.address.city ? contact.address.city + ', ' : '';
    address += contact.address.state ? contact.address.state + ', ' : '';
    address += contact.address.zipCode ? contact.address.zipCode + ', ' : '';
    address += contact.address.country ? contact.address.country + ', ' : '';
    address = address.substr(0, address.length - 2);
    return address;
  }
}
