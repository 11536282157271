import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {ConfigDataService} from './config-data.service';
import {inject} from '@angular/core';


export interface Config {
    geo_url: string;
}


@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    private readonly configDataService = inject(ConfigDataService);

    readonly _config$ = new ReplaySubject<Config>(1);

    constructor() {
        const observer = {
            next: (config: Config) => {
                this._config$.next(config);
            },
            error: (err: any) => {
                console.error('failed to pull config. using default...', err);
                this._config$.next({geo_url: 'https://geo-services.app.utah.edu'});
            },
        };


        this.configDataService.fetchConfig().subscribe(observer);
    }

    get config$(): Observable<Config> {
        return this._config$;
    }
}
