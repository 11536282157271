<div class="w-100 bg-dark">
  <footer class="pt-3 pb-2 mt-5 align-self-end container-fluid">
    <div class="row small text-white">
      <div class="col-sm-4">
        <p>
          <a href="https://giving.utah.edu">{{officeContact.address.department}}</a><br>
          {{officeContact.address.addressOne}}<br>
          {{officeContact.address.city}}, {{officeContact.address.state}}, {{officeContact.address.zip}}<br>
          <a href="tel:{{officeContact.phone}}">{{officeContact.phone}}</a><br>
          <a href="tel:{{officeContact.tollFree}}">{{officeContact.tollFree}}</a><br>
          <a href="mailto:{{officeContact.email}}">{{officeContact.email}}</a><br><br>
          &copy; <a href="http://www.utah.edu" target="_blank">{{officeContact.organization}}</a>
        </p>
      </div>
      <div class="col-sm-4">
        <p>
          <a href="http://www.utah.edu/nondiscrimination/" target="_blank">
            Nondiscrimination &amp; Accessibility
          </a><br/>
          <a href="http://www.utah.edu/disclaimer/" target="_blank">
            Disclaimer
          </a><br/>
          <a href="http://www.utah.edu/privacy/" target="_blank">
            Privacy
          </a>
        </p>
      </div>
      <div class="col-sm-4">
        <a href="https://imagineu.utah.edu" target="_blank" *ngIf="!customTheme">
          <img src="assets/images/imagine_u.svg" class="imagine img-fluid" alt="imagine u"/>
        </a>
      </div>
    </div>
  </footer>
</div>
