import {Component, Inject} from '@angular/core';
import {DOCUMENT} from '@angular/common';


@Component({
  selector: 'app-skip-link',
  templateUrl: './skip-link.component.html',
  styleUrls: ['./skip-link.component.scss']
})
export class SkipLinkComponent {

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  skipToMainContent(): void {
    this.document.getElementById('uu-skip-target').focus();
  }

}
