import { Component, OnInit } from '@angular/core';
import {GiftService} from '../../gift-information/gift.service';
import {Gift} from '../../gift-information/gift';
import {DesignationService} from '../../designation/designation.service';
import {Designation} from '../../designation/designation';
import {CustomerService} from '../../customer/customer.service';

@Component({
  selector: 'app-gift-summary',
  templateUrl: './gift-summary.component.html',
  styleUrls: ['./gift-summary.component.scss']
})
export class GiftSummaryComponent implements OnInit {

  public giftInfo: Gift;
  public designation: Designation;


  constructor(private giftService: GiftService,
              private designationService: DesignationService,
              private readonly customerService: CustomerService) {
  }

  get customTheme(): boolean {
    return this.customerService.requiresCustomization();
  }

  ngOnInit() {
    this.fetchDesignation();
    this.giftInfo = this.giftService.retrieveGift();
  }


  fetchDesignation() {
    const id: number = +this.giftService.retrieveGift().designationId;
    return this.designationService.getDesignation(id).subscribe(designation => {
      this.designation = designation;
    });
  }

}
