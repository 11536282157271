import {Component, OnInit} from '@angular/core';
import {GiftInformationNavService} from '../gift-information/gift-information-nav.service';
import {HomeStateService} from './home-state.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  constructor(private giftInformationNavService: GiftInformationNavService,
              private readonly homeStateService: HomeStateService) {
  }

  ngOnInit() {
    this.homeStateService.resetState();
    this.giftInformationNavService.reset();
  }

}
