<h2 class="form-label-underline">Is This a Joint Gift?</h2>
<div class="mb-3 row" [formGroup]="form">
  <label for="spouseName" class="col-sm-3 col-form-label"><strong>Name of Spouse/Partner:</strong></label>
  <div class="col-sm-9 ">
    <input type="text" class="form-control" id="spouseName" formControlName="spouseName"
           name="spouseName" maxlength="100" placeholder="Name of Spouse/Partner">
    <app-field-errors [field]="form.controls.spouseName">
    </app-field-errors>
  </div>
</div>
