import {Component, DestroyRef, OnInit} from '@angular/core';
import {GiftInformationNavService} from '../gift-information-nav.service';
import {BaseGiftInformation} from '../base-gift-information';
import {GiftService} from '../gift.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormValidationService} from '../../shared/validation/form-validation.service';
import {GeoService} from '../../shared/geo/geo.service';
import {Country} from '../../shared/geo/country';
import {Province} from '../../shared/geo/province';
import {CountrySupport} from '../../shared/geo/country-support';
import {applyMixins} from '../../shared/mix-it-up';
import {Router} from '@angular/router';
import {Gift} from '../gift';
import {NotificationService} from '@uofu-uss/angular-util';

@Component({
    selector: 'app-notify-someone',
    templateUrl: './notify-someone.component.html',
    styleUrls: ['./notify-someone.component.scss'],
})
export class NotifySomeoneComponent extends BaseGiftInformation implements OnInit, CountrySupport {
    notificationForm: FormGroup;

    // properties from CountrySupport mixin
    countries: Country[];
    provinces: Province[];
    loadCountries: (geoService: GeoService, notificationService: NotificationService, form: FormGroup) => void;
    initChangeSupport: (geoService: GeoService, notificationService: NotificationService, form: FormGroup, destroyRef: DestroyRef) => void;


    constructor(giftService: GiftService,
                giftInformationNavService: GiftInformationNavService,
                notificationService: NotificationService,
                router: Router,
                private readonly fb: FormBuilder,
                private readonly formValidationService: FormValidationService,
                private readonly geoService: GeoService,
                private readonly destroyRef: DestroyRef) {
        super(giftService, giftInformationNavService, notificationService, router);
    }

    ngOnInit() {
        const gift = this.giftService.retrieveGift();
        this.initReactiveForm(gift);
        this.loadCountries(this.geoService, this.notificationService, this.notificationForm);
        this.initChangeSupport(this.geoService, this.notificationService, this.notificationForm, this.destroyRef);
    }

    private initReactiveForm(gift: Gift): void {
        this.notificationForm = this.fb.group({
            name: [gift.notification.name, [Validators.required, Validators.maxLength(100)]],
            address1: [gift.notification.address.address1, [Validators.required, Validators.maxLength(100)]],
            address2: [gift.notification.address.address2, [Validators.maxLength(100)]],
            city: [gift.notification.address.city, [Validators.required, Validators.maxLength(100)]],
            country: [gift.notification.address.country, [Validators.required]],
            province: [gift.notification.address.state],
            zipCode: [gift.notification.address.zipCode, [Validators.required, Validators.maxLength(20)]],
        });
    }

    updateAndProceed() {
        const gift = this.updateNotifySomeoneInfo();
        if (gift) {
            this.next(gift);
        }
    }

    // This function updates 'notify someone' info in the session storage
    updateNotifySomeoneInfo(): Gift {
        if (this.notificationForm.valid) {
            const gift = this.giftService.retrieveGift();
            this.updateNotification(gift);
            this.giftService.storeGift(gift);
            return gift;
        } else {
            this.formValidationService.showValidationErrors([this.notificationForm]);
            return null;
        }
    }

    updateNotification(gift: Gift) {
        gift.notification.name = this.notificationForm.controls.name.value;
        gift.notification.address.address1 = this.notificationForm.controls.address1.value;
        gift.notification.address.address2 = this.notificationForm.controls.address2.value;
        gift.notification.address.city = this.notificationForm.controls.city.value;
        gift.notification.address.state = this.notificationForm.controls.province.value;
        gift.notification.address.zipCode = this.notificationForm.controls.zipCode.value;
        gift.notification.address.country = this.notificationForm.controls.country.value;
    }

}

applyMixins(NotifySomeoneComponent, [CountrySupport]);
