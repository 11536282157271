import {Component, Input, OnInit} from '@angular/core';
import {Affiliation} from '../../contact-information/personal-information/affiliation';
import {Gift} from '../../gift-information/gift';

@Component({
  selector: 'app-uofu-affiliation',
  templateUrl: './uofu-affiliation.component.html',
  styleUrls: ['./uofu-affiliation.component.scss']
})
export class UofuAffiliationComponent implements OnInit {

  @Input()
  gift: Gift;
  affiliation: Affiliation;

  constructor() {
  }

  ngOnInit() {
    const contact = this.gift.contact;
    this.affiliation = this.affiliationNeeded(contact.affiliation) ? contact.affiliation : null;
  }

  private affiliationNeeded(aff: Affiliation): boolean {
    return aff && (aff.facultyOrStaff ||
      aff.friend ||
      aff.student ||
      aff.alum);
  }

}
