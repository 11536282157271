import {Component, Input, OnInit} from '@angular/core';
import {DesignationService} from '../designation.service';
import {Designation} from '../designation';

@Component({
  selector: 'app-designation-groups',
  templateUrl: './designation-groups.component.html',
  styleUrls: ['./designation-groups.component.scss']
})
export class DesignationGroupsComponent implements OnInit {

  @Input()
  designationLetter: string;
  @Input()
  designationList: Designation[];

  public designationListSplit: Array<Designation[]>;


  constructor(public designationService: DesignationService) {
  }

  ngOnInit() {
    this.initDesignationsListByDesignationLetter();
    this.initSplitArray();
  }

  initDesignationsListByDesignationLetter() {
    this.designationList = this.designationService.filterAndSortDesignationsByLetter(this.designationLetter, this.designationList);
  }

  // used to hide initial top link on the 'A' section
  notA(): boolean {
    return this.designationLetter !== 'A';
  }

  // used to split the array so that it may be displayed down then right in order instead of right then down.
  initSplitArray(): void {
    if (this.designationList && this.designationList.length) {
      this.designationListSplit = this.chunk(this.designationList, Math.ceil(this.designationList.length / 3));
    } else {
      this.designationListSplit = [];
    }
  }
  chunk<t>(arr: t[], chunkSize = 1, cache = []): t[][] {
    const tmp = [...arr];
    if (chunkSize <= 0) {
      return cache;
    }
    while (tmp.length) {
      cache.push(tmp.splice(0, chunkSize));
    }
    return cache;
  }
}
