import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from './config.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigDataService {
  private readonly httpClient = inject(HttpClient);
  constructor() { }

    fetchConfig(): Observable<Config> {
        return this.httpClient.get<Config>('/ugive-services/config');
    }
}
