<form name="givenow" id="givenow" style="display: none" *ngIf="gift" action="{{uccpConfiguration.paymentUrl}}" method="POST" autocomplete="off">
  <input type="hidden" name="App_Type" value="{{uccpConfiguration.account}}">
  <input type="hidden" name="ID" value="{{gift.id}}">
  <input type="hidden" name="amt" value="{{gift.amount}}">
  <input type="hidden" name="~First_Name" value="{{gift.contact.name.firstName}}">
  <input type="hidden" name="~Last_Name" value="{{gift.contact.name.lastName}}">
  <input type="hidden" name="~Address" value="{{gift.contact.address.address1}}">
  <input type="hidden" name="~City" value="{{gift.contact.address.city}}">
  <input type="hidden" name="~State" value="{{isValidState(gift.contact.address.state) ? gift.contact.address.state : 'NA'}}">
  <input type="hidden" name="~Zip" value="{{gift.contact.address.zipCode}}">
  <input type="hidden" name="~Country" value="{{gift.contact.address.country}}">
  <input type="hidden" name="~Email" value="{{gift.contact.email}}">
  <input type="hidden" name="~Phone" value="{{gift.contact.phone}}">
  <input type="submit" value="Continue">
</form>
