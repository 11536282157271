import {Injectable} from '@angular/core';
import {GiftInformationView} from './gift-information-view';
import {Gift} from './gift';

@Injectable({
  providedIn: 'root'
})
export class GiftInformationNavService {

  private _workflow: GiftInformationView[] = [
    GiftInformationView.GIFT_AMOUNT,
    GiftInformationView.NOTIFY_SOMEONE,
  ];
  private _currentView: GiftInformationView;

  constructor() {
    this.reset();
  }

  get workflow(): GiftInformationView[] {
    return this._workflow;
  }

  get currentView(): GiftInformationView {
    return this._currentView;
  }

  set currentView(view: GiftInformationView) {
    this._currentView = view;
  }

  reset() {
    this.currentView = this._workflow[0];
  }

  nextView(current: GiftInformationView, gift: Gift): GiftInformationView {
    let skip = false;
    if (!this.notify(gift) && current === GiftInformationView.GIFT_AMOUNT) {
      skip = true;
    }
    const nextIndex = this.getNextIndex(current, skip);
    return nextIndex >= this._workflow.length ? null : this._workflow[nextIndex];
  }

  previousView(current: GiftInformationView): GiftInformationView {
    const skip = false;
    const previousIndex = this.getPreviousIndex(current, skip);
    return previousIndex < 0 ? null : this._workflow[previousIndex];
  }

  private notify(gift: Gift): boolean {
    return gift.notification.notify || false;
  }

  public isLastPage(current: GiftInformationView, gift: Gift): boolean {
    return current === this._workflow[this._workflow.length - 1] ||
      (current === GiftInformationView.GIFT_AMOUNT && !gift.notification.notify);
  }

  private getNextIndex(view: GiftInformationView, skip = false): number {
    return this._workflow.indexOf(view) + (skip ? 2 : 1);
  }

  private getPreviousIndex(view: GiftInformationView, skip = false): number {
    return this._workflow.indexOf(view) - (skip ? 2 : 1);
  }

}
