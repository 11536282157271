import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule, HttpClientXsrfModule} from '@angular/common/http';

import {AppComponent} from './app.component';

import {AppRoutingModule} from './app-routing.module';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GiftService} from './gift-information/gift.service';
import {ConfirmationReceiptModule} from './confirmation-receipt/confirmation-receipt.module';
import {GiftInformationModule} from './gift-information/gift-information.module';
import {ContactInformationModule} from './contact-information/contact-information.module';
import {DesignationModule} from './designation/designation.module';
import {SharedModule} from './shared/shared.module';
import {HomeModule} from './home/home.module';
import {ActiveGiftGuard} from './active-gift.service';
import {CategoryService} from './category/category.service';
import {AccessibilityModule} from '@uofu-uss/angular-util';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'UGIVE-XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN'
    }),
    SharedModule,
    AppRoutingModule,
    HomeModule,
    DesignationModule,
    GiftInformationModule,
    ContactInformationModule,
    ConfirmationReceiptModule,
    AccessibilityModule,
    NgbModule
  ],
  providers: [
    GiftService,
    ActiveGiftGuard,
    CategoryService,
    {provide: Document, useValue: document},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
