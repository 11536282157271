import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {BaseContactInfoComponent} from '../base-contact-info/base-contact-info.component';
import {emailCrossValidator} from '../../shared/validation/email-cross-validator';

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss'],
  inputs: ['contact'],
})
export class PersonalInformationComponent extends BaseContactInfoComponent  implements OnInit {
  @Input() isDonationFromBusiness: boolean;


  constructor(private readonly fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.form = this.fb.group({
      title: [this.contact.title, []],
      firstName: [this.contact.name.firstName, [Validators.required, Validators.maxLength(30)]],
      middleName: [this.contact.name.middleName, [Validators.maxLength(30)]],
      lastName: [this.contact.name.lastName, [Validators.required, Validators.maxLength(30)]],
      phone: [this.contact.phone, [Validators.required, Validators.maxLength(20)]],
      email: [this.contact.email, [Validators.required, Validators.maxLength(50), Validators.email]],
      confirmEmail: [this.contact.email, [Validators.required, Validators.maxLength(50), Validators.email]]
    }, {validator: emailCrossValidator});
  }

  save() {
    this.contact.title = this.form.controls.title.value;
    this.contact.name.firstName = this.form.controls.firstName.value;
    this.contact.name.middleName = this.form.controls.middleName.value;
    this.contact.name.lastName = this.form.controls.lastName.value;
    this.contact.phone = this.form.controls.phone.value;
    this.contact.email = this.form.controls.email.value;
  }
}
