import {Injectable} from '@angular/core';
import {DesignationImage, DesignationImageService} from './designation-image.service';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {EMPTY, Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})

export class DesignationImageResolverService implements Resolve<DesignationImage> {

  constructor(private readonly imageService: DesignationImageService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<DesignationImage> | Promise<DesignationImage> | DesignationImage {
    const id = route.paramMap.get('id');
    return this.imageService.getImageIds(id).pipe(catchError(() => {
      return of({} as DesignationImage);
    }));
  }
}

@Injectable({
  providedIn: 'root'
})

export class DefaultDesignationImageResolverService implements Resolve<DesignationImage> {

  constructor(private readonly imageService: DesignationImageService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<DesignationImage> | Promise<DesignationImage> | DesignationImage {
    return this.imageService.getImageIds(0).pipe(catchError(() => {
      return of({} as DesignationImage);
    }));
  }
}
