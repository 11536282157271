<div class="ms-1 mt-4 row justify-content-between">
  <div class="col-lg-11 col-sm-10">
    <h2 class="contribution-header">{{title}}</h2>
  </div>
  <div class="col-lg-1 col-sm-2 d-grid">
    <button type="button" *ngIf="canEdit" class="btn btn-edit border-dark"
            routerLink="{{editLink}}"
            queryParamsHandling="preserve">
      EDIT</button>
  </div>
</div>
<hr>
