import {Component, Input, OnInit} from '@angular/core';
import {Gift} from '../../gift-information/gift';

@Component({
  selector: 'app-business-donation',
  templateUrl: './business-donation.component.html',
  styleUrls: ['./business-donation.component.scss']
})
export class BusinessDonationComponent implements OnInit {

  @Input() receipt = false;
  @Input() gift: Gift;
  businessName = '';

  constructor() {
  }

  ngOnInit() {
    this.businessName = this.gift.contact.businessName;
  }

}
