import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({opacity: 1})),
      state('hidden', style({opacity: 0, display: 'none'})),
      transition('shown => hidden', animate('300ms')),
      transition('hidden => shown', animate('300ms')),
    ])
  ]
})
export class LoaderComponent implements OnChanges {

  @Input() loading: boolean;

  public visibilityState = 'hidden';

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.visibilityState = this.loading ? 'shown' : 'hidden';
  }

}
