import {Country, countrySorter} from './country';
import {first} from 'rxjs/operators';
import {GeoService} from './geo.service';
import {FormGroup, Validators} from '@angular/forms';
import {Province} from './province';
import {Subscription} from 'rxjs/internal/Subscription';
import {OfficeContactMessage} from '../office-contact/office-contact-message';
import {NotificationService} from '@uofu-uss/angular-util';
import {DestroyRef} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


function loadProvinces(geoService: GeoService, notificationService: NotificationService,
                       countryCode: string, form: FormGroup, countrySupport: CountrySupport): void {
    countrySupport.provinces = null;
    form.controls.province.clearValidators();
    form.controls.province.updateValueAndValidity();
    if (countryCode) {
        const selectedCountry = countrySupport.countries.find((country) => country.shortCode === countryCode);
        geoService.provinces(selectedCountry)
            .pipe(first())
            .subscribe((provinces) => {
                countrySupport.provinces = provinces;
                if (countrySupport.provinces.length) {
                    form.controls.province.setValidators([Validators.required]);
                    form.controls.province.updateValueAndValidity();
                    // only add a blank if needed.
                    if (!form.controls.province.value) {
                        countrySupport.provinces.unshift({code: null, name: null});
                    }
                }
            }, (error) => {
                notificationService.addWarning(`Unable to load states/provinces. ${OfficeContactMessage.instance.contactIfErrorContinues}`);
            });
    }
}

export class CountrySupport {
    countries: Array<Country>;
    provinces: Array<Province>;

    /**
     * Subscribe to changes in the country and province.
     * When changes are detected on the country field, blank rows are filtered,
     * the correct provinces are loaded, and the province set in the form is cleared.
     * When changes are detected on the province field, a blank rows are filtered.
     *
     * Note: it is necessary to filter blank rows to make sure consistent behaviour is present in all browsers.
     * In general, a blank line is when a field is loaded.
     * This data row is removed when a selection is made.
     */
    public initChangeSupport(geoService: GeoService,
                             notificationService: NotificationService,
                             form: FormGroup,
                             destroyRef: DestroyRef): void {
        const countryChangeSubscription =
            form.controls.country.valueChanges
                .pipe(takeUntilDestroyed(destroyRef))
                .subscribe((value) => {
                    this.countries = this.countries.filter((country) => !!country.shortCode);
                    form.controls.province.setValue(null);
                    loadProvinces(geoService, notificationService, value, form, this);
                }, (error) => {
                    notificationService.addWarning(`Unable to load countries. ${OfficeContactMessage.instance.contactIfErrorContinues}`);
                });

        const provinceChangeSubscription =
            form.controls.province.valueChanges
                .pipe(takeUntilDestroyed(destroyRef))
                .subscribe((value) => {
                    if (this.provinces && this.provinces.length) {
                        this.provinces = this.provinces.filter((province) => !!province.code);
                    }
                });
    }

    /**
     * Load the countries and provinces
     */
    public loadCountries(geoService: GeoService, notificationService: NotificationService, form: FormGroup): void {
        geoService.countries()
            .pipe(first())
            .subscribe((countries): void => {
                this.countries = countries.sort(countrySorter);
                // only default to US if they do not already have a selection.
                if (countries && countries.length && !form.controls.country.value) {
                    form.controls.country.setValue('US');
                }
                loadProvinces(geoService, notificationService, form.controls.country.value, form, this);
            }, () => {
                notificationService.addWarning(`Unable to load countries. ${OfficeContactMessage.instance.contactIfErrorContinues}`);
            });
    }

}

