import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HomeStateService {

  choosingCategory = false;
  choosingGreatestNeeds = false;
  choosingCovid = false;

  constructor() {
  }

  public resetState() {
    this.choosingCategory = false;
    this.choosingGreatestNeeds = false;
    this.choosingCovid = false;
  }
}
