<h2 class="h3 card-title text-condensed mt-5 mb-3">Choose a Fund</h2>
<hr>

<div class="card-body text-center">
  <ul class="list-unstyled">
    <li class="d-grid gap-3">
      <button class="btn btn-primary" type="button"
              routerLink="/designation/3544"
              routerLinkActive="active">
        COVID-19 Crisis Response
      </button>
      <br>
    </li>
    <li class="d-grid gap-3">
      <button class="btn btn-primary" type="button"
              routerLink="/designation/3625"
              routerLinkActive="active">
        Campus Arts & Culture Organizations
      </button>
      <br>
    </li>
    <li class="d-grid gap-3">
      <button class="btn btn-primary" type="button"
              routerLink="/designation/3624"
              routerLinkActive="active">
        Emergency Student Support
      </button>
      <br>
    </li>
  </ul>
</div>

<div class="row justify-content-center m-2">
  <button id="back" class="btn btn-primary w-auto" (click)="goBack()">BACK</button>
</div>
