import { Component } from '@angular/core';

@Component({
  selector: 'app-huntsman-header',
  templateUrl: './huntsman-header.component.html',
  styleUrls: ['./huntsman-header.component.scss']
})
export class HuntsmanHeaderComponent {

  constructor() { }

}
