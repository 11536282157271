<div class="card bg-light text-center">
  <div class="card-body">
    <h3 class="card-title mb-3"><b>Your Gift</b></h3>
    <div class="card-text mb-5">
      <div class="row justify-content-center">
        <p>Comfort words and phrases... here's where you're going.</p>
      </div>
      <div class="row center-buttons">
        <button class="btn btn-primary m-2" type="button" (click)="oneTimeGift();">
          ONE-TIME GIFT
        </button>
        <button class="btn btn-primary m-2" type="button" (click)="recurringGift()">
          RECURRING
        </button>
      </div>
    </div>
  </div>
</div>




