import {Injectable} from '@angular/core';
import {Designation} from './designation';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DesignationService {

  public readonly NONE_OF_THE_ABOVE_ID = 305;


  constructor(private readonly http: HttpClient) {
  }

  getDesignations(): Observable<Designation[]> {
    return this.http.get<Designation[]>('/ugive-services/designation');
  }

  getGreatestNeed(): Observable<Designation[]> {
    return this.http.get<Designation[]>('/ugive-services/designation/greatest-need');
  }

  getDesignation(id: number): Observable<Designation> {
    return this.http.get<Designation>('/ugive-services/designation/' + id);
  }

  getDesignationsByCategory(id: number | string): Observable<Designation[]> {
    return this.http.get<Designation[]>(`/ugive-services/designation?category=${id}`);
  }

  isDisabledOrExpired(designation: Designation): boolean {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return designation ? (designation.disabled || (designation.expirationDate && designation.expirationDate < today)) : true;
  }

  isCustomDesignation(designation: Designation): boolean {
    return +designation.id === this.NONE_OF_THE_ABOVE_ID;
  }

  filterAndSortDesignationsByLetter(letter: string, designations: Designation[] = []): Designation[] {
    return designations.filter((designationItem) => {
      const nameNoNumbers = designationItem.name.replace(/[0-9 ]*/, '');
      return nameNoNumbers.toUpperCase().startsWith(letter.toUpperCase());
    }).filter((designationItem) => !this.isDisabledOrExpired(designationItem))
      .filter((designationItem) => !this.isCustomDesignation(designationItem))
      .sort((a, b) => {
        return a.name.toUpperCase().localeCompare(b.name.toUpperCase());
      });
  }
}
