import {Component, OnDestroy, OnInit} from '@angular/core';
import {GiftInformationNavService} from '../gift-information-nav.service';
import {BaseGiftInformation} from '../base-gift-information';
import {GiftService} from '../gift.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormValidationService} from '../../shared/validation/form-validation.service';
import {Gift} from '../gift';
import {DedicationType} from '../dedication-type';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {DesignationService} from '../../designation/designation.service';
import {AmountInputValidator, NotificationService} from '@uofu-uss/angular-util';

@Component({
  selector: 'app-gift-amount',
  templateUrl: './gift-amount.component.html',
  styleUrls: ['./gift-amount.component.scss']
})
export class GiftAmountComponent extends BaseGiftInformation implements OnInit, OnDestroy {

  giftAmountForm: FormGroup;
  dedicationSubscription: Subscription;
  isDedicatingGift: boolean;

  constructor(giftService: GiftService,
              giftInformationNavService: GiftInformationNavService,
              notificationService: NotificationService,
              private readonly fb: FormBuilder,
              private readonly formValidationService: FormValidationService,
              private readonly currentRoute: ActivatedRoute,
              private readonly designationService: DesignationService,
              router: Router) {
    super(giftService, giftInformationNavService, notificationService, router);
  }

  ngOnInit() {
    const gift = this.giftService.retrieveGift();
    const querySpecialInstructions = this.currentRoute.snapshot.queryParamMap.get('specialInstructions');
    this.giftAmountForm = this.fb.group({
      amount: [gift.amount, [Validators.min(1), Validators.required, new AmountInputValidator().validate(2)]],
      dedicationType: [gift.dedication.type, []],
      name: [gift.dedication.name, [Validators.required, Validators.maxLength(100)]],
      notifySomeone: [gift.notification.notify, []],
      specialInstructions: [querySpecialInstructions || gift.specialInstructions, this.getSpecialInstructionsValidation()]
    });
    this.updateDedicateType(gift.dedication.type);
    this.subscribeToDedicationChanges();
  }

  ngOnDestroy(): void {
    this.dedicationSubscription.unsubscribe();
  }

  subscribeToDedicationChanges() {
    this.dedicationSubscription = this.giftAmountForm.controls.dedicationType.valueChanges.subscribe((next) => {
      this.updateDedicateType(next);
    });
  }

  updateAndProceed() {
    const gift = this.updateGift();
    if (gift) {
      this.next(gift);
    }
  }

  updateDedicateType(dedicateType: DedicationType) {
    this.updateGiftDedicationInfo(dedicateType);
    this.isDedicatingGift = (dedicateType === DedicationType.HONOR || dedicateType === DedicationType.MEMORY);
    if (this.isDedicatingGift) {
      this.giftAmountForm.controls['name'].enable();
    } else {
      this.giftAmountForm.get('name').disable();
      this.giftAmountForm.controls.name.setValue(null);
      this.giftAmountForm.controls.notifySomeone.setValue(null);
    }
  }

  /*
   * This function updates gift.
   * @return true if things are valid and the component was saved. otherwise return false.
   */
  updateGift(): Gift {
    if (this.giftAmountForm.valid) {
      const gift = this.giftService.retrieveGift();

      gift.amount = this.giftAmountForm.controls.amount.value;
      gift.dedication.name = this.giftAmountForm.controls.name.value;
      gift.dedication.dedicate = gift.dedication.name != null;
      gift.notification.notify = this.giftAmountForm.controls.notifySomeone.value;
      gift.specialInstructions = this.giftAmountForm.controls.specialInstructions.value;
      this.giftService.storeGift(gift);
      return gift;
    } else {
      this.formValidationService.showValidationErrors([this.giftAmountForm]);
      return null;
    }
  }

  // This function updates dedication info in the session storage
  updateGiftDedicationInfo(dedicateType: DedicationType) {
    const gift = this.giftService.retrieveGift();
    gift.dedication.type = dedicateType;
    this.giftService.storeGift(gift);
  }

  specialInstructionsRequired(): boolean {
    return this.giftService.retrieveGift().designationId === this.designationService.NONE_OF_THE_ABOVE_ID;
  }

  getSpecialInstructionsValidation() {
    const validation = [Validators.maxLength(2000)];
    if (this.specialInstructionsRequired()) {
      validation.push(Validators.required);
    }
    return validation;
  }
}
