import { Injectable } from '@angular/core';
import {Category} from '../home/category';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) { }

  fetchCategories(parent?: string) {
    if (!parent) {
      return this.http.get<Category[]>('/ugive-services/category?rootLevel=true&active=true');
    }
    return this.http.get<Category[]>('/ugive-services/category?active=true&parent=' + parent);
  }
}
