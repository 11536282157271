import {Link} from './link';

export interface Country {
  code: string;
  shortCode: string;
  longName: string;
  shortName: string;
  links: Link[];
}

export const countrySorter = (left: Country, right: Country): number => {
  return left.longName.localeCompare(right.longName);
};
