import {Component, Input, OnInit} from '@angular/core';
import {GiftService} from '../../gift-information/gift.service';
import {AddressBuilderService} from '../address-builder.service';
import {Contact} from '../../contact-information/contact';
import {Gift} from '../../gift-information/gift';

@Component({
  selector: 'app-my-info',
  templateUrl: './my-info.component.html',
  styleUrls: ['./my-info.component.scss']
})
export class MyInfoComponent implements OnInit {

  @Input() receipt = false;
  @Input() gift: Gift;
  name = '';
  phone = '';
  email = '';
  address = '';

  constructor(private addressService: AddressBuilderService) {
  }

  ngOnInit() {
    const contact = this.gift.contact;
    this.buildName(contact);
    this.phone = contact.phone;
    this.email = contact.email;
    this.address = this.addressService.buildContactAddress(contact);
  }

  buildName(contact: Contact) {
    if (contact.title) {
      this.name += contact.title + ' ';
    }
    this.name += contact.name.firstName + ' ';
    if (contact.name.middleName) {
      this.name += contact.name.middleName + ' ';
    }
    this.name += contact.name.lastName;
  }

}
