import {AfterViewChecked, Component, Input} from '@angular/core';
import {Gift} from '../../gift-information/gift';
import {UccpConfiguration} from '../uccp.service';

@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.scss']
})
export class MakePaymentComponent implements AfterViewChecked {

  gift: Gift;
  @Input() uccpConfiguration: UccpConfiguration;

  constructor(private readonly document: Document) {
  }

  ngAfterViewChecked() {
    const form = this.document.forms['givenow'];
    if (form) {
      form.submit();
    }
  }

  submit(savedGift: Gift) {
    this.gift = savedGift;
  }

  isValidState(state: string): boolean {
    return state && state.length === 2;
  }

}
